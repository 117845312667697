import React from 'react'
import ScaleLoader from "react-spinners/ScaleLoader";
const ScaleLoaderItem = ({loading}:{loading:boolean}) => {
  return (
    <div className='border-transperent'>
        <ScaleLoader
        loading={loading}
        color={"#4CC6DE"}
        height={350}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> 
    </div>
  )
}

export default ScaleLoaderItem