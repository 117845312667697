// import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useSearchedUserList = () => {


   const searchFunction =  async ({city, searchString}:{city:string, searchString:string}) => {
      try {
      
        const response = await fetch(
          `${BASEURL}/v1/user/search?city=${city}&searchString=${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data);
        let UserList = result?.data;

        // setSearchedPropertyList(propertyData);
       
        return UserList;
      } catch (err) {
       console.log(err)
      } 
    };

    const allUserRole = async()=>{
      try {
          
        const response = await fetch(
          `${BASEURL}/v1/user/userrole`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data);
        let UserRole = result?.data;

        // setSearchedPropertyList(propertyData);
       
        return UserRole;
        
      } catch (error) {
        
      }
    }

  return { searchFunction, allUserRole };
};

export default useSearchedUserList ;
