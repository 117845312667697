import { useState } from "react";

export default function EmaiPrompt({
    openPrompt,
    setOpenPrompt,
    emails,
    setEmails
  }: {
    openPrompt: boolean;
    setOpenPrompt: any;
    emails: string[];
    setEmails: any;
  }) {
    const [openInput, setOpenInput] = useState<boolean>(false)
  
      const handleFormSubmit = (e:any)=>{
        e.preventDefault()
        if(e.target.email.value){
           if (!emails.includes(e.target.email.value)){

               emails.push(e.target.email.value)
           }else{
            console.log("this emsail alredy exist")
           }
        }
        setOpenInput(false)
      }
      const handleFilter = (elm:any)=>{
        // console.log("elm", elm)
       let filteredEmails = emails.filter((email)=> {
            return email !== elm
        })
        setEmails(filteredEmails)
        // console.log(filteredEmails)
      }
    return (
      <>
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setOpenPrompt(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="mb-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray mb-4">
                    Please enter the email address to which you want to send this sheet.
                    </h4>
                    {/* <div className="">
                    <div
                          
                          className="border rounded border-borderColor p-2"
                        >
                          <input
                            type="checkbox"
                            value=""
                            name={"1"}
                            defaultChecked={true}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handleClick}
                          />
                          <label className="ml-2 	 text-sm font-sans font-light">
                            <input 
                            className="ml-2  text-sm font-sans font-light"
                            type="email"
                            name="email"
                            />
                          </label>
                        </div>
                    </div> */}

                    <div className="shadow mb-2 appearance-none border-recity border rounded w-full py-2 px-3 text-gray leading-tight "
>
                        {
                            emails?.map((item)=>{
                                return <div className="flex flex-row m-2">
                                    <div>{item}</div>
                                    <button className="ml-2 text-recity" onClick={()=>handleFilter(item)}>x</button>
                                </div>
                            })
                        }
                    </div>
                    {
                       openInput && 
                       <form onSubmit={(e)=>handleFormSubmit(e)}>

                        <input 
                         className="shadow appearance-none border-recity border rounded w-full py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline"
                          id="email"
                           type="email" 
                           name="email"
                           placeholder="Email">
                           </input>
                           <button type="submit" className="group uppercase mt-2  relative flex w-1/4 justify-center rounded-md border border-transparent bg-recity py-2 px-4 text-sm font-medium text-white hover:bg-darkBlue focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Submit</button>
                       </form>

                        
                    }
                    {
                        !openInput &&
                    <button
                      className="w-1/3 bg-lightBlue text-Black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpenInput(true)}
                    >
                      Add Email
                    </button>
                    }

  
                    <div className="items-center gap-3 mt-3 sm:flex">
                    <button
                      className="w-full bg-lightBlue text-Black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpenPrompt(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-full bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpenPrompt(false)}
                    >
                      Done
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
  