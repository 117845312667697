import React,{useState, useEffect, useCallback} from "react";
import useClusterListData from "../data-fetch/clusterlist-data-fetch";
import useSearchedClustersList from "../data-fetch/cluster-search";
import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
// import FilterComponent from "./FilterComponent";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import format from 'date-fns/format';
// import ViewCluster from "../ViewCluster";
import { useNavigate } from "react-router-dom";
import FilterComponent from "./FilterComponent";

// import { IoSwapVerticalOutline } from "react-icons/io5";
const rowHeading = [{heading: "Cluster ID" ,key: 'clusterID',  isChecked:true},  {heading:"Number of Properties",  isChecked:true}, {heading:"Ward no.",key: 'wardNo', isChecked:true}, {heading:"Latitude",key: 'latitude', isChecked:true},{heading:"Longitude",key: 'longitude', isChecked:true},{heading:"Landmark",key: 'landmark', isChecked:true},{heading:"Area",key: 'area', isChecked:true}, {heading:"WW Assigned",key: 'wwAssigned', isChecked: true}, {heading:"created By",key: 'createdBy', isChecked: true},{heading:"Action",key: '', isChecked: true} ]

// const propertyTypeArr:any[] = [
//     {type:"All Select", isChecked:false},
//     // {type:null, isChecked:false},
//     // {type:"", isChecked:false},
//     {type:"Area less than 2000 sq. Ft", isChecked:false},
//     {type:"Area more than 2000 sq. Ft", isChecked:false},
//     {type:"Commercial", isChecked:false},{type:"Residential", isChecked:false},{type:"Shops (daily needs)", isChecked:false},{type:"Hospitality", isChecked:false},{type:"Vegetable or fruit vendor", isChecked:false},{type:"Bakeries", isChecked:false},{type:"Bakeries with manufacturing unit", isChecked:false},{type:"Bank", isChecked:false},{type:"Office building", isChecked:false},{type:"Hospital or Nursing home", isChecked:false},{type:"Educational", isChecked:false},{type:"Religious", isChecked:false},{type:"PG / Hostels / Guesthouse", isChecked:false}, {type:"Marriage hall / Banquets hall", isChecked:false}, {type:"Dharamshala", isChecked:false},
//     {type:"Chemist shop", isChecked:false},
//     {type:"Chicken / Meat Shop", isChecked:false},
//     {type:"Cinema hall", isChecked:false},
//     {type:"Clinic", isChecked:false},
//     {type:"Clinic + Medical shop", isChecked:false},
//     {type: "Garage", isChecked:false},
//     {type:"Lab", isChecked:false},
//     {type:"Old Age/ Orphanage", isChecked:false},
//     {type:"Petrol Pump", isChecked:false},
//     {type: "Residential + Commercial", isChecked:false},
//     {type: "Scrap Dealer", isChecked:false},
//     {type:"Street vendor", isChecked:false},
//     {type:"Other", isChecked:false}

// ]

export default function Table({city,  skip, limit, setTotalCount, setCurrentPage}:{city:string, skip:number, limit:number, setTotalCount:any, setCurrentPage:any}) {
    const navigate = useNavigate()
    const {searchFunction} = useSearchedClustersList();
     ////// Date Range Selector
     const [dayDiff, setDaydiff] =useState<any>()
     const today = new Date()
     const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [range, setRange] = useState<any[]>([
       {
         endDate: new Date(),
         startDate: today.setDate(today.getDate()-6),
         key: 'selection', 
       }
     ]);
 
     useEffect(()=>{
 
         setToDate(format(range[0].endDate, "yyyy-MM-dd"))
         setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
     
         setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
     
       },[range, toDate, fromDate]);
    //  ///////////////

    //  useEffect(()=>{
    //     setCurrentPage(1)
    //  },[range, toDate, fromDate])

    
    // console.log("propertyList",propertyList);
    const [openPropertyDetails, setOpenpropertyDetails] = useState<boolean>(false)
    // const [allPropertyList, setAllPropertyList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [property, setProperty] = useState<any>({});
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);
    const [ openFilter, setOpenFilter] = useState<boolean>(false)
    const [filteredPropertyType, setFilteredPropertyType] = useState<any[]>([]);
    const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    useEffect(()=>{
        // console.log(filteredPropertyType)
        let currentPropertyTypes:string[] = [];
        filteredPropertyType.forEach((item)=>{
            if(item.isChecked){
                currentPropertyTypes.push(item.type)
            }
        })
        setPropertyTypes(currentPropertyTypes)
        // console.log("currentPropertyTypes", currentPropertyTypes)
    },[filteredPropertyType])

    const { clusterList, loading} = useClusterListData({city:city, skip:skip, limit:limit, setTotalCount:setTotalCount});
    
    type Data = typeof clusterList;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(clusterList)
        
    },[ clusterList])

    const headers = [
        { label: 'PU DDN', key: 'PUDDN' },
        { label: 'Owner Name', key: 'ownersname' },
        { label: 'Contact No.', key: 'contactNo' },
        { label: 'Ward No.', key: 'wardNo' },
        { label: 'No of Days waste not segregated', key: 'noOfDaysWaste' },
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);
     
    //   useEffect(()=>{
    //     setFilteredPropertyType(propertyTypeArr);
      
    //   },[propertyTypeArr]);

   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
      
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: clusterList, sortKey, reverse : sortOrder === 'desc'}),[clusterList, sortKey, sortOrder])

      ///
      useEffect(()=>{
        setData(sortedData())
    },[sortedData]);

    //   useEffect(()=>{
    //     setAllPropertyList(data.slice(skip, (skip + limit)))
    // },[skip, limit, data, sortedData]);
    
    // useEffect(()=>{
    //     console.log(data)
    // }, [data])
   
    const handleSearchText = ( value:string)=>{
        setSearchText(value)           
  
    }   

  const search = async()=>{

       const searchedPropertylist = await searchFunction({city:city, searchString:searchText});
    //    console.log("searchedPropertylist", searchedPropertylist)
       
       if(searchText !== "" ){
           // setTotalCount(searchedPropertylist.length());
           
           setData(searchedPropertylist)
       }else{
           setData(clusterList)
       }
     
   }
         useEffect(()=>{
            if(searchText !== ""){

                let searchFuc:any =  setTimeout(()=>{
                     search();
                 }, 3000);
                 return () => clearTimeout(searchFuc)
            } else{
                setData(clusterList)
            }
        },[ searchText])

    // const filterData = (value:string)=>{
    //     const lowerCaseValue =  value.toLowerCase().trim();
    //     // console.log("lowerCaseValue",lowerCaseValue);
        
    //     if(!lowerCaseValue){
    //         setData(sortedData())
    //     }else {
    //         const filteredData = sortedData().filter(item =>{
    //             // console.log("item", item)
    //             return Object.keys(item).some(key =>{
                   
    //                 return item[key].toString().toLowerCase().includes(lowerCaseValue)
    //             })
    //         });
    //         // console.log("filteredData",filteredData);
            
    //         setData(filteredData)
    //         // console.log(data);
            
    //     }
    // } 
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        }   
        
        const handleViewProperty = async (cluster:any)=>{
            navigate(`/cluster/view/${cluster._id}`)
            // setProperty(property)
        //    const propertyDetails =await getPropertyDetails(ddn)
        // setOpenpropertyDetails(true);
            // console.log(property)
        }
        const handleDelete = ()=>{
            console.log("this cluster will get Permantly deleted and it's cant be revert")
        }
  
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleSearchText(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>

                 

                    {/* <div className="flex items-center border border-gray rounded-md mr-4" >
                        <div className="relative">
                        <CSVLink data={sortedData()} filename={`Defaulters_list_${city}_${fromDate}_${toDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download 
                                        
                                    </div>
                                </span>
                            </button>
                            </CSVLink>
                        </div>
                    </div> */}
                    {/* <div className='flex justify-end  '>
                    { <DateRangeSelector range={range} setRange={setRange}  />}

                </div> */}
                </div>
                {/* <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter}  filteredPropertyType ={filteredPropertyType } setFilteredPropertyType={setFilteredPropertyType}/>}
                </div> */}
                
                <div className="p-1.5 inline-block min-w-full align-middle">
                    <div className="overflow-visible border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-6 py-3 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.isChecked &&  item.heading }
                                        
                                        {/* {item.isChecked && item.heading  ===  "No. of days waste not segregated"  ? ' ⇵' : '' }{item.isChecked && item.heading ===   'Ward no.' ? ' ⇵' : ''}{item.isChecked && item.heading ===  "Owner's Name"  ? ' ⇵' : '' } */}
                                    </th>
                                            )
                                        })
                                    }
                                    
                                    
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    data?.map((item, index)=>{
                                        // console.log("item", item);
                                        
                                        return(
                                            <tr key={index} className="hover:bg-tableBg">

                                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap uppercase">
                                                {filteredColumn[0].isChecked && item?.clusterDDN}
                                            </td>
                                            <td className="px-2 py-4 text-sm text-gray-800 whitespace-nowrap">
                                                {filteredColumn[1].isChecked && item?.properties?.length}
                                            </td>
                                            <td className=" py-4 text-sm text-gray-800 whitespace-nowrap">
                                            {filteredColumn[2].isChecked && item?.wardNumber}
                                            </td>
                                            
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {filteredColumn[4].isChecked &&` ${item?.location?.coordinates[1]}`  }
                                                
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {filteredColumn[5].isChecked &&` ${item?.location?.coordinates[0]}`  }
                                                
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {filteredColumn[3].isChecked &&`NA`  }
                                                
                                            </td><td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                               {filteredColumn[3].isChecked &&`NA`  }
                                           
                                       </td>
                                            {/* <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                               {filteredColumn[6].isChecked && item?.city}
                                           
                                       </td>
                                       <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                               
                                               {filteredColumn[7].isChecked && (item.state) }
                                           
                                       </td> */}
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[6].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                                    { item.userCreatedBy}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[6].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                                    { item.createdby}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[6].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                <button
                                                    type="button"
                                                    className={`hover:bg-recity hover:text-white p-2 disabled:opacity-25`}
                                                    onClick={()=>handleViewProperty(item)}
                                                    //   disabled={currentPage * limit > totalCount ? true : false}
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-recity hover:text-white">
                                                        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                                        <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                                        </svg>


                                                 </button>

        <button className="mx-2" onClick={()=>handleDelete()}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-red">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>

        </button>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
        </div>
    );
}