import {  useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useScannedClusterDetails = ({wwid,  date}:{wwid:string, date:string}) => {

  const [ scannedClusterList, setClusterList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  


  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
        console.log("date", date)
       
        const response = await fetch(
          `${BASEURL}/v1/clusters/scanned-cluster?wwid=${wwid}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
       
        let scannedClusters = result?.data;
       
        setClusterList(scannedClusters);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [wwid, date  ]);

  


  return {   scannedClusterList, loading, error };
};

export default useScannedClusterDetails ;
