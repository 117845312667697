import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useFeesCollectionData = ({city, toDate, fromDate, skip, limit, setTotalCount}:{city:string, toDate:string,fromDate:string, skip:number, limit:number, setTotalCount:any}) => {

  const [feesCollectionData, setFeesCollectionData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/fee-collections?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        console.log("result", result);
        const feesCollectionDataArray = result.data.feeCollections
        // console.log("feesCollectionDataArray", feesCollectionDataArray);
        const feesCollectionInfo = feesCollectionDataArray.map((item:any)=>{
          return {ddn  : item?.ddn, ownersname:item?.survey?.propertyOwnerName, contactNo:item?.survey?.contactNumber, propertyStatus:item?.propertyStatus, collectionStatus:item?.collectionStatus, reschedule:item?.rescheduled
            , paymentType: item?.payment?.type,paymentStatus: item?.payment?.status,paymentAmount: item?.payment?.amount, wardNo: item?.survey?.wardNumber, collected_by: item?.collected_by}
        })
        // console.log("feesCollectionInfo", feesCollectionInfo);
        setTotalCount(result.data.totalCount)
        setFeesCollectionData(feesCollectionInfo);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {  feesCollectionData, loading, error };
};

export default useFeesCollectionData ;
