import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './component/Login-page-component/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import DefaulterList from './component/Defaulter-List/DefaulterList';
import AttendanceList from './component/Attendance-List/AttendanceList';
import Ucf from './component/Fees-collection/Ucf';
import Report from './component/Report/Report';
import Insights from './component/Dashboard-Component/Main-contenet/Dashboard/Insights';
import ReportSheet from './component/Report-Sheets/ReportSheet';
import WardWiseClustureReport from './component/Report/WardWise/WardWiseClustorReport';
import WasteWorkerWiseReport from './component/Report/WasteWorkerWise/WasteWorkerWiseReport';
import AllProperties from './component/Property-Managment/All-Properties/AllProperties';
import TspProperties from './component/Property-Managment/Tsp/Tsp';
import ViewProperty from './component/Property-Managment/View-Property/ViewProperty';
import AllClusters from './component/Cluster-Managment/All-Clusters/AllClusters';
import ViewCluster from './component/Cluster-Managment/View-Cluster/ViewCluster';
import GoogleMap from './component/Fleet-managment/GoogleMap';
import FmsInsights from './component/FMS/FmsMSW';
import VehicleLists from './component/Fleet-managment/Vehicle-management/Vehicl';
import AddVehicle from './component/Fleet-managment/Vehicle-management/AddVehicle';
import ViewVehicle from './component/Fleet-managment/Vehicle-management/ViewVehicle';
import AllUserList from './component/User-Management/AllUserList';
import ViewUser from './component/User-Management/View-User/ViewUser';

import { format } from 'date-fns';
function App() {
  

  ///////////////////////////////////DATE range for Cluster report ////////////////////////
    ////// Date Range Selector
    const [dayDiff, setDaydiff] =useState<any>()
    const today = new Date()
    const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [range, setRange] = useState<any[]>([
      {
        endDate: new Date(),
        startDate: today.setDate(today.getDate()-6),
        key: 'selection', 
      }
    ]);

    useEffect(()=>{

        setToDate(format(range[0].endDate, "yyyy-MM-dd"))
        setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
    
        setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
    
      },[range, toDate, fromDate]);
   //  ///////////////
  window.addEventListener("offline", ()=>{
    toast("Please Check your Internet Connection!!")    
  })
  const [selectCity, setSelectCity] = useState("Select City");
  return (

    <div className="App">
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<Home selectCity={selectCity} setSelectCity={setSelectCity}/>} >
          <Route path="/" element={<Insights city={selectCity} />} />
          <Route path="/defaulters-list" element={<DefaulterList city={selectCity} />} />
          <Route path="/attendance" element={<AttendanceList city={selectCity} />} />
          <Route path="/ucf" element={<Ucf city={selectCity} />} />
          <Route path="/fms" element={<FmsInsights city={selectCity} />} />
          <Route path="/report-sheets" element={<ReportSheet city={selectCity}/>} />
          <Route path="/reports" > 
            <Route path='/reports/city' element={<Report city={selectCity} range={range} setRange={setRange} />} />
            <Route path='/reports/ward' element={<WardWiseClustureReport city={selectCity} range={range} setRange={setRange}/>} />
            <Route path='/reports/cluster' element={<WasteWorkerWiseReport city={selectCity} range={range}/>} />
          </Route>
          <Route path='/property'>
            <Route path="/property/all" element={<AllProperties city={selectCity}/>} />
            <Route path="/property/view/:id" element={<ViewProperty />} />
            <Route path="/property/tsp" element={<TspProperties city={selectCity}/>} />
          </Route>
          <Route path='/cluster'>
            <Route path="/cluster/all" element={<AllClusters city={selectCity}/>} />
            <Route path="/cluster/view/:id" element={<ViewCluster />} />
          </Route>
          <Route path='/user'>
            <Route path="/user/all" element={<AllUserList city={selectCity}/>} />
            <Route path="/user/view/:id" element={<ViewUser />} />
          </Route>
          <Route path='/fleet'>
            <Route path="/fleet/location" element={<GoogleMap city={selectCity}/>} />
            <Route path="/fleet/vehicle" element={<VehicleLists city={selectCity}/>} />
            <Route path="/fleet/vehicle/add" element={<AddVehicle city={selectCity}/>} />
            <Route path="/fleet/vehicle/:id" element={<ViewVehicle city={selectCity}/>} />
          </Route>
        </Route>
        </Route>
        <Route path="*" element={<Navigate to="/"  replace/>}/>
        <Route path='/login' element={<Login />}/>
      </Routes>
      
    <ToastContainer />
    </div>
  );
}

export default App;
