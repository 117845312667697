import React from 'react'
import FeesInsights from './FeesInsights'
import UserFeeCollectionList from './UserFeesList'

const Ucf = ({city}:{city:string}) => {
  return (
    <div>
      <FeesInsights city={city} />
      <UserFeeCollectionList city={city} />
    </div>
  )
}

export default Ucf