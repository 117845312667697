import LineChart from '../../../Graphs/LineChart';
import ScaleLoaderItem from '../../../../Ui-Elemnent/ScaleLoader';
import useBagsRecievedDetails from './data-fetch/fetchBagsDetails';
const BagsRecieved = ({city, fromDate, toDate, setNoOfBagsRecieved }:{city:string, fromDate:string, toDate:string, setNoOfBagsRecieved:any}) => {

  const {bagsRecievedData, loading} = useBagsRecievedDetails({city:city, fromDate:fromDate, toDate:toDate, setNoOfBagsRecieved:setNoOfBagsRecieved})
  const option = {
    maintainAspectRatio: false,
    
  }
  
  
    return (
      <div>
      {loading ? <ScaleLoaderItem loading={loading}/>: <LineChart chartData={bagsRecievedData} option={option}/>  }
      </div>
      )
}

export default BagsRecieved