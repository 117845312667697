import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useWardWiseClusterData = ({city, toDate, fromDate, wardNumber, skip, limit, setTotalCount, dayDiff}:{city:string, toDate:string,fromDate:string, skip:number, limit:number, setTotalCount:any, dayDiff: number, wardNumber: number | string}) => {

  const [wardClustersData, setWardClustersData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const data1:any= {clusterList:[
  //   {"date":'01/01/2022',"wardNo":1,"totalClustersAvailable":41,"totalScannedClusters":32,"totalUnscannedClusters":9,"clusterScanningPercent":80},
  //   {"date":'02/01/2022',"wardNo":1,"totalClustersAvailable":41,"totalScannedClusters":32,"totalUnscannedClusters":9,"clusterScanningPercent":80},
  //   {"date":'03/01/2022',"wardNo":1,"totalClustersAvailable":41,"totalScannedClusters":32,"totalUnscannedClusters":9,"clusterScanningPercent":80},
  //   {"date":'01/01/2022',"wardNo":2,"totalClustersAvailable":33,"totalScannedClusters":28,"totalUnscannedClusters":5,"clusterScanningPercent":85},
  //   {"date":'02/01/2022',"wardNo":2,"totalClustersAvailable":33,"totalScannedClusters":28,"totalUnscannedClusters":5,"clusterScanningPercent":85},
  //   {"date":'03/01/2022',"wardNo":2,"totalClustersAvailable":33,"totalScannedClusters":28,"totalUnscannedClusters":5,"clusterScanningPercent":85},
  //   {"date":'01/01/2022',"wardNo":3,"totalClustersAvailable":22,"totalScannedClusters":19,"totalUnscannedClusters":3,"clusterScanningPercent":86},
  //   {"date":'02/01/2022',"wardNo":3,"totalClustersAvailable":22,"totalScannedClusters":19,"totalUnscannedClusters":3,"clusterScanningPercent":86},
  //   {"date":'03/01/2022',"wardNo":3,"totalClustersAvailable":22,"totalScannedClusters":19,"totalUnscannedClusters":3,"clusterScanningPercent":86},
  //   {"date":'',"wardNo":4,"totalClustersAvailable":25,"totalScannedClusters":21,"totalUnscannedClusters":4,"clusterScanningPercent":84},
  //   {"date":'',"wardNo":5,"totalClustersAvailable":18,"totalScannedClusters":18,"totalUnscannedClusters":0,"clusterScanningPercent":100},
  //   {"date":'',"wardNo":6,"totalClustersAvailable":37,"totalScannedClusters":31,"totalUnscannedClusters":7,"clusterScanningPercent":81},
  //   {"date":'',"wardNo":7,"totalClustersAvailable":36,"totalScannedClusters":30,"totalUnscannedClusters":6,"clusterScanningPercent":83},
  //   {"date":'',"wardNo":8,"totalClustersAvailable":42,"totalScannedClusters":32,"totalUnscannedClusters":10,"clusterScanningPercent":76},
  //   {"date":'',"wardNo":9,"totalClustersAvailable":50,"totalScannedClusters":45,"totalUnscannedClusters":5,"clusterScanningPercent":90},
  //   {"date":'',"wardNo":10,"totalClustersAvailable":21,"totalScannedClusters":20,"totalUnscannedClusters":1,"clusterScanningPercent":95},
  //   {"date":'',"wardNo":11,"totalClustersAvailable":30,"totalScannedClusters":21,"totalUnscannedClusters":9,"clusterScanningPercent":70},
  //   {"date":'',"wardNo":12,"totalClustersAvailable":42,"totalScannedClusters":29,"totalUnscannedClusters":13,"clusterScanningPercent":69},
  //   {"date":'',"wardNo":13,"totalClustersAvailable":27,"totalScannedClusters":19,"totalUnscannedClusters":8,"clusterScanningPercent":70},
  // ]};
  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/reports/cluster-report/ward-wise?wardNo=${wardNumber}&city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data?.wasteWorkerList);
        // const wards = Object.keys(result.data.clusterList);
        // // console.log("wards", wards)
        // const propertiesInfo = wards.map((index) => {
        //     return  {wardNo : result.data.clusterList[index].wardNo, totalClustersAvailable: result.data.clusterList[index].totalClustur, totalScannedClusters:Math.round(result.data.clusterList[index].totalScannedClusters/ dayDiff) , totalUnscannedClusters:Math.round(result.data.clusterList[index].totalClustur - Math.round(result.data.clusterList[index].totalScannedClusters/dayDiff)) , clusterScanningPercent: (100 * Math.round(result.data.clusterList[index].totalScannedClusters/ dayDiff) / result.data.clusterList[index].totalClustur).toFixed(2) };
        //   });
          
        // console.log("propertiesInfo", propertiesInfo);
        setTotalCount(result?.data?.wasteWorkerList.length)
        setWardClustersData(result?.data?.wasteWorkerList);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {  wardClustersData, loading, error };
};

export default useWardWiseClusterData ;
