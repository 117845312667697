import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useWardWiseClusterData from "../data-fetch/ward-wise-cluster";
import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
import FilterComponent from "../FilterComponent";
import { CSVLink } from "react-csv";
import DateRangeSelector from "../../Ui-Elemnent/DateRange";
import format from "date-fns/format";
const rowHeading = [
  { heading: "Ward No", key: "wardNo", isChecked: true },
  { heading: "Waste Worker ID", key: "wasteWorkerID", isChecked: true },
  { heading: "Name", key: "name", isChecked: true },
  { heading: "Assigned clusters", key: "assignedClusters", isChecked: true },
  { heading: "Scanned Clusters", key: "totalScannedClusters", isChecked: true },
  {
    heading: "Un-scanned Clusters",
    key: "totalUnscannedClusters",
    isChecked: true,
  },
  {
    heading: "Un Assign-scanned Clusters",
    key: "unAssignedScannedClusters",
    isChecked: false,
  },
  {
    heading: "% of Clusters scanning",
    key: "clusterScanningPercent",
    isChecked: true,
  },
];

export default function WardWiseClustureTable({
  city,
  skip,
  limit,
  setTotalCount,
  range,
  setRange
}: {
  city: string;
  skip: number;
  limit: number;
  setTotalCount: any;
  range:any,
  setRange:any
}) {
  ////// Date Range Selector
  const [dayDiff, setDaydiff] = useState<any>();
  const today = new Date();
  const [toDate, setToDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [fromDate, setFormDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  // const [range, setRange] = useState<any[]>([
  //   {
  //     endDate: new Date(),
  //     startDate: today.setDate(today.getDate() - 6),
  //     key: "selection",
  //   },
  // ]);

  useEffect(() => {
    // console.log("ward cluster table", range)
    setToDate(format(range[0].endDate, "yyyy-MM-dd"));
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"));

    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
  }, [range, toDate, fromDate]);
  ///////////////
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // console.log("wardNumber", searchParams.get('wardNo'))
  const wardNumber = searchParams.get("wardNo") || 1;
  const { wardClustersData, loading } = useWardWiseClusterData({
    city: city,
    toDate: toDate,
    fromDate: fromDate,
    skip: skip,
    limit: limit,
    setTotalCount: setTotalCount,
    dayDiff: dayDiff + 1,
    wardNumber: wardNumber,
  });
  // console.log("wardClustersData",wardClustersData);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [filteredColumn, setFilteredColumn] = useState<any[]>([]);

  type Data = typeof wardClustersData;
  type SortKey = keyof Data[0];
  type SortOrder = "ascn" | "desc";
  useEffect(() => {
    setData(wardClustersData);
  }, [wardClustersData]);

  const headers = [
    { label: "Ward No", key: "wardNo" },
    { label: "Waste Worker ID", key: "wasteWorkerID" },
    { label: "Name", key: "name" },
    { label: "Assigned clusters", key: "assignedClusters" },
    { label: "Scanned Clusters", key: "totalScannedClusters" },
    { label: "Un-scanned Clusters", key: "totalUnscannedClusters" },
    { label: "Un Assign-scanned Clusters", key: "unAssignedScannedClusters" },
    { label: "% of Clusters scanning", key: "clusterScanningPercent" },
  ];
  useEffect(() => {
    setFilteredColumn(rowHeading);
  }, [rowHeading]);

  // sorting functionality
  const [sortKey, setSortKey] = useState<SortKey>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKey;
    reverse: boolean;
  }) {
    //    console.log("sortKey", sortKey);

    if (!sortKey) return tableData;

    const sortedData = tableData.sort((a, b) => {
      if (sortKey === "wardNo") {
        return Number(a[sortKey]) > Number(b[sortKey]) ? 1 : -1;
      }
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }
    return sortedData;
  }

  const sortedData = useCallback(
    () =>
      sortData({
        tableData: wardClustersData,
        sortKey,
        reverse: sortOrder === "desc",
      }),
    [wardClustersData, sortKey, sortOrder]
  );

  ///
  useEffect(() => {
    setData(sortedData());
  }, [sortedData]);

  useEffect(() => {
    setClusterList(data.slice(skip, skip + limit));
  }, [skip, limit, data, sortedData]);

  const handleChange = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    filterData(searchText);
  }, [searchText]);

  const filterData = (value: string) => {
    const lowerCaseValue = value.toLowerCase().trim();
    // console.log("lowerCaseValue",lowerCaseValue);

    if (!lowerCaseValue) {
      setData(sortedData());
    } else {
      const filteredData = sortedData().filter((item) => {
        // console.log("item", item)
        return Object.keys(item).some((key) => {
          return item[key].toString().toLowerCase().includes(lowerCaseValue);
        });
      });
      // console.log("filteredData",filteredData);

      setData(filteredData);
      // console.log(data);
    }
  };
  const handleSortClick = (sortKey: string) => {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(sortKey);
    // console.log(sortKey);
  };

  useEffect(() => {
    // console.log("dayDiff", dayDiff + 1)
  }, [dayDiff]);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto ">
        <div className="flex justify-start py-3 pl-2 ">
          <div className="relative max-w-xs mr-4">
            <label htmlFor="hs-table-search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => handleChange(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
          <div className="flex items-center border border-gray rounded-md mx-4">
            <div className="relative">
              <button
                className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
                onClick={(e) => setOpenFilter(!openFilter)}
              >
                <span className="relative inline-flex items-center px-3 py-3  space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </div>
                  <div className="hidden sm:block">Filters</div>
                </span>
              </button>
            </div>
          </div>
          <div className="flex items-center border border-gray rounded-md mr-4">
            <div className="relative">
              <CSVLink
                data={data?.length !== 0 && data !== undefined ? data : ""}
                filename={`Clusture_Report_${city}_${fromDate}_${toDate}`}
                headers={headers}
              >
                <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1">
                  <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                    <div>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:block">Download</div>
                  </span>
                </button>
              </CSVLink>
            </div>
          </div>
          <div className="flex justify-end  ">
            {<DateRangeSelector range={range} setRange={setRange} />}
          </div>
          <button
            className={`border rounded rounded-lg bg-white hover:bg-recity hover:text-white p-2 disabled:opacity-25 ml-2 `}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
        <div className="absolute justify-center z-20 ">
          {openFilter && (
            <FilterComponent
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filteredColumn={filteredColumn}
              setFilteredColumn={setFilteredColumn}
            />
          )}
        </div>

        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            {loading ? (
              <ScaleLoaderItem loading={loading} />
            ) : (
              <table className="min-w-full divide-y divide-recity">
                <thead className="bg-recity">
                  <tr>
                    {filteredColumn.map((item, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-4 py-2 text-l font-bold text-center text-white uppercase "
                          onClick={(e) => handleSortClick(item.key)}
                        >
                          {item.isChecked && item.heading}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  {clusterList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                          {filteredColumn[0].isChecked && item?.wardNo}
                        </td>
                        <td className=" py-2 text-sm text-gray-800 whitespace-nowrap">
                          {
                            filteredColumn[1].isChecked && item?.wasteWorkerID
                            // `RC-WBKP-WW-1-000002`
                          }
                        </td>
                        <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                          {filteredColumn[2].isChecked && item?.name}
                        </td>
                        <td className="py-2 text-md text-recity whitespace-nowrap ">
                          <button
                            className="underline font-bold"
                            onClick={() =>
                              navigate(
                                `/reports/cluster?wardNo=${item?.wardNo}&wwid=${item?.wasteWorkerID}`
                              )
                            }
                          >
                            {filteredColumn[3].isChecked &&
                              Math.round(item?.assignedClusters)}
                          </button>
                        </td>
                        <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                          {filteredColumn[4].isChecked &&
                            Math.round(item?.totalScannedClusters)}
                        </td>
                        <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                          {filteredColumn[5].isChecked &&
                            Math.round(item?.totalUnscannedClusters)}
                        </td>
                        <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                          {filteredColumn[6].isChecked &&
                            Math.round(item?.unAssignedScannedClusters)}
                        </td>
                        <td
                          className={`py-2 text-sm text-gray-800 whitespace-nowrap`}
                        >
                          {filteredColumn[7].isChecked &&
                            `${item?.clusterScanningPercent}%`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
