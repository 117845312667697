import React, { useEffect, useState } from 'react'
import DateRangeSelector from '../../../../Ui-Elemnent/DateRange'
import CityWiseMaterialTransfer from './CityWiseMaterialTransfer'
import  BagsRecieved from './BagsTransfer'
import format from 'date-fns/format'
// const TabName = ["Ward Wise Collection", "Ward Wise Collection Vs Segregation", "Ward Wise Attendance" ]

const MaterialTransferData = ({city}:{city:string}) => {
  const [selectedTab, setSelectedTab] =  useState("Amount Of Waste Received");
  const [calendar, setCalendar] = useState<any>((new Date()));
  const [selectdDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [showExtraInfo, setShowExtraInfo] = useState("");
  ///////////
  const [dayDiff, setDaydiff] =useState<any>()
  const today = new Date()
  const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [range, setRange] = useState<any[]>([
    {
      endDate: new Date(),
      startDate: today.setDate(today.getDate()-6),
      key: 'selection', 
    }
  ])
  ///////
   const [totalAmountofWaste, setTotalAmountofWaste] = useState<number>(0);
   const [noOfBagsRecieved, setNoOfBagsRecieved] = useState<number>(0);
   const [totalTspCountInWard, setTotalTspCountInWard] = useState<number>(0);
   const [cleanTspCountInWard, setCleanTspCountInWard] = useState<number>(0);


  ///////
  useEffect(()=>{

    setToDate(format(range[0].endDate, "yyyy-MM-dd"))
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
  
  },[range])
  ///////

  useEffect(()=>{
    setSelectedDate(format(calendar, "yyyy-MM-dd"))
  },[calendar])

  const TabName = [{tabName:"Amount Of Waste Received", numerator: totalAmountofWaste, extraInfo:"Shows the type of the material received by Vehicle/Driver represented in weight (Kg/Tonne) for a selected timeline."} , {tabName:"No. of Bags Received", numerator: noOfBagsRecieved/(dayDiff +1),  extraInfo:"Shows the no. of bags received by Vehicle/Driver for a selected timeline. If timeline is more than one day then its shows averaged"} ]

  const HandleOnMouseEnter = (tabName:string)=>{
    setShowExtraInfo(tabName)
  }
  const HandleOnMouseLeave = ()=>{
    setShowExtraInfo("")
  }


  return (
    <div className='w-full ml-2 mt-8 h-1/3'>
     <div className='flex justify-end mr-4'>
         <DateRangeSelector range={range} setRange={setRange}  />
      </div>
      <div className='border-2 border-t-0 bg-white'>
      <div className='flex justify-between'>
        {
          TabName.map((item, index)=>{
            return(
              <div key={index} className={`h-12  border text-white w-1/2 cursor-pointer hover:bg-lightBlue hover:text-recity py-2 border border-black  ${
                selectedTab === item.tabName
                  ? "bg-lightBlue text-recity"
                  : "text-white bg-recity"
              }`} onClick={()=>setSelectedTab(item.tabName)} onMouseEnter={()=>HandleOnMouseEnter(item.tabName)} onMouseLeave={HandleOnMouseLeave}> 
              <h1 className='text-2xl font-medium text-center'>{item.tabName}{` (${Math.floor(item.numerator).toString()})`} </h1>
              <div className={`relative text-sm rounded-lg bg-black text-white items-end mx-auto mt-2 py-4 ${showExtraInfo !== item.tabName ? "hidden": ""}`}>{item.extraInfo}</div>
          </div>
            )
          })
        }
      </div>
      <div className='hidden'>
      <BagsRecieved city={city} fromDate={fromDate} toDate={toDate} setNoOfBagsRecieved={setNoOfBagsRecieved}/>
       <CityWiseMaterialTransfer city={city} fromDate={fromDate} toDate={toDate} setTotalAmountofWaste={setTotalAmountofWaste} />
         
    </div>

    <div>
      {
       selectedTab === "No. of Bags Received" ? <BagsRecieved city={city} fromDate={fromDate} toDate={toDate} setNoOfBagsRecieved={setNoOfBagsRecieved}/>: <CityWiseMaterialTransfer city={city} fromDate={fromDate} toDate={toDate} setTotalAmountofWaste={setTotalAmountofWaste} />
      }    
    </div>
    </div>
    </div>
  )
}

export default MaterialTransferData
