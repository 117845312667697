import React from 'react'
export default function ImageViwer({
    setOpenImage,
    imageUrl
  }: {
    setOpenImage: any;
    imageUrl:string   
  }) {

    return (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="mb-2 text-center sm:ml-4 sm:text-left">
                    <div>
                        <img src={`${imageUrl}`} alt='Property'/>
                    </div>
                    <div className="items-center gap-3 mt-3 sm:flex">
                    <button
                      className="w-full bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpenImage(false)}
                    >
                      Done
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }