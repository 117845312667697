import React,{useState, useEffect, useCallback} from "react";
import useAttendanceData from "./data-fetch/fetch-attendance";
import useReportListData from "./data-fetch/attendance-report";
import ScaleLoaderItem from "../Ui-Elemnent/ScaleLoader";
import FilterComponent from "./FilterComponent";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../Ui-Elemnent/DateRange'
import CalenderSelctor from '../Ui-Elemnent/Calender';
import format from 'date-fns/format';

import useAllWWList from "./data-fetch/ww-attendance";
const rowHeading = [{heading: "s. no." ,  isChecked:true},  {heading:"Name",  isChecked:true},  {heading:"SHIFT_IN",  isChecked:true},  {heading:"SHIFT_OUT",  isChecked:true} ]

const header = ['Name', "User ID", 'ATTENDANCE',  'SHIFT IN Time', 'SHIFT OUT time']
	

export default function WWAttendanceTable({city,  skip, limit, setTotalCount, setCurrentPage}:{city:string, skip:number, limit:number, setTotalCount:any, setCurrentPage:any}) {
    
     ////// Date Range Selector

     const today = new Date();

    

    const [calendar, setCalendar] = useState<any>(
        today.setDate(today.getDate())
    );
    
    const [selectdDate, setSelectedDate] = useState(format(new Date(), "dd/MM/yyyy"));
    useEffect(() => {
        setSelectedDate(format(calendar, "dd/MM/yyyy"));
    }, [calendar]);
    

     ///////////////

     useEffect(()=>{
        setCurrentPage(1)
     },[selectdDate])


    const {userList, loading} = useAllWWList({city:city,skip:skip, limit:limit, date:selectdDate, setTotalCount:setTotalCount});
    // const {reporData, loadingReport} = useReportListData({city:city,toDate:toDate,fromDate:fromDate,skip:skip, limit:limit});
    console.log("attendance table userList", userList);
    // console.log("attendanceData",attendanceData);
    let headersData:any = [];
    // if(reporData.length !=0){
    //     const { userId, ...newObject } = reporData[0];
    //     headersData = Object.keys(newObject).map(key => ({
    //         label: key,
    //         key: key
    //     }));
    // }
    
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [openDownload, setOpenDownload] = useState<boolean>(false)
    const [attedanceList, setAttendanceList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [dataReport, setDataReport] = useState<any[]>([]);
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);
    
   

    const headers = [
        { label: 'Name', key: 'name' },
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

   // sorting functionality
      
    

    const handleChange = ( value:string)=>{
        setSearchText(value)
           
         }   

        // useEffect(()=>{
        //     filterData(searchText)
        // },[searchText])

    // const filterData = (value:string)=>{
    //     const lowerCaseValue =  value.toLowerCase().trim();
    //     // console.log("lowerCaseValue",lowerCaseValue);
        
    //     if(!lowerCaseValue){
    //         setData(sortedData())
    //     }else {
    //         const filteredData = sortedData().filter(item =>{
    //             // console.log("item", item)
    //             return Object.keys(item).some(key =>{
                   
    //                 return item[key].toString().toLowerCase().includes(lowerCaseValue)
    //             })
    //         });
    //         // console.log("filteredData",filteredData);
            
    //         setData(filteredData)
    //         // console.log(data);
            
    //     }
    // } 
            
  
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center border border-gray rounded-md mx-4" >
                        <div className="relative">
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" onClick={e => setOpenFilter(!openFilter)}>
                                <span className="relative inline-flex items-center px-3 py-3  space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-3 h-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Filters
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* <div className="flex items-center border border-gray rounded-md mr-4" >
                        <div className="relative">
                            <CSVLink data={dataReport.length !== 0 && dataReport !== undefined ? dataReport : ''} filename={`Attendance_Report_list_${city}_${fromDate}_${toDate}`} headers={headersData}>
                                <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"  onClick={e => setOpenDownload(!openDownload)}>
                                    <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                        <div>
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        </div>
                                        <div className="hidden sm:block">
                                            Download
                                        </div>
                                    </span>
                                </button>
                            </CSVLink>
                        </div>
                    </div> */}
                    <div className='flex justify-end  '>
                    {/* { <DateRangeSelector range={range} setRange={setRange}  />} */}
                    <CalenderSelctor calendar={calendar} setCalendar={setCalendar} />

                </div>
                </div>
                <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter}  filteredColumn={filteredColumn} setFilteredColumn={setFilteredColumn}/>}
                </div>
                
                <div className="p-1.5 inline-block min-w-full align-middle">
                    <div className="overflow-visible border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                            <tr>
                                {/* Assuming your header and subheader arrays are defined */}
                                {header.map((col, index) => (
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-l font-bold text-center text-white uppercase"
                                    key={index}
                                >
                                    {col}
                                </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {userList?.map((item:any, rowIndex:any) => (
                                <tr key={rowIndex} className="hover:bg-tableBg">

                                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap uppercase">
                                    { item?.name}
                                </td>
                                <td className="px-2 py-4 text-sm text-gray-800 whitespace-nowrap">
                                    { item?.username}
                                </td>
                                <td className=" py-4 text-sm text-gray-800 uppercase whitespace-nowrap">
                                { item?.status}
                                </td>
                                
                                <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                   
                                        {` ${item?.attendances?.[0]?.inTime || "N A"}`  }
                                    
                                </td>
                                <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                   
                                {` ${item?.attendances?.[0]?.outTime || "N A"}`  }
                                    
                                </td>
                              
                        
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}