import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useClusterReportData = () => {

//   const [propertySurveyData, setPropertySurveyData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
 
const clusterReportSheet =  (async ({city, email, toDate, fromDate}:{city:string, email:string[], toDate:string, fromDate:string}) => {
    try {
      
      setLoading(true);
     
      const response = await fetch(
        `${BASEURL}/v1/reports/cluster-summury?city=${city}&email=${email}&to=${toDate}&from=${fromDate}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      // console.log("result", result);
      // setPropertySurveyData(result.data.collectionDetails)
        
      
     
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  })

  return {  clusterReportSheet,loading, error };
};

export default useClusterReportData ;
