import React, { useEffect, useState } from "react";
import AttendanceChart from "./AttendanceChart";
// import DateRangeSelector from '../../../Ui-Elemnent/DateRange'
import CalenderSelctor from "../../../../Ui-Elemnent/Calender";
import ScannedCollectionChart from "./ScannedCollectionChart";
import SegregationVsCollectionChart from "./SegragationVsCollectionChart";
import format from "date-fns/format";
// const TabName = ["Ward Wise Collection", "Ward Wise Collection Vs Segregation", "Ward Wise Attendance" ]

const WardWiseData = ({ city }: { city: string }) => {
  const today = new Date();
  const [selectedTab, setSelectedTab] = useState(
    "Ward Wise Scanned Collection"
  );
  const [calendar, setCalendar] = useState<any>(
    today.setDate(today.getDate() - 1)
  );
  const [selectdDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [showExtraInfo, setShowExtraInfo] = useState("");
  ///////////
  const [totalPropertiesInWard, setTotalPropertiesInWard] = useState<number>(1);
  const [scannedPropertiesInWard, setScannedPropertiesInWard] =
    useState<number>(0);
  const [segrigatedCollectionInWard, setSegrigatedCollectionInWard] =
    useState<number>(0);
  const [presentUserCount, setPresentUserCount] = useState<number>(0);
  const [totaluserCount, setTotalUseCount] = useState<number>(1);

  ///////

  useEffect(() => {
    setSelectedDate(format(calendar, "yyyy-MM-dd"));
  }, [calendar]);

  const TabName = [
    {
      tabName: "Ward Wise Scanned Collection",
      numerator: scannedPropertiesInWard,
      denominator: totalPropertiesInWard,
      extraInfo:
        "Shows the number of properties scanned out of the total number of properties registered for a particular ward for the selected date.",
    },
    {
      tabName: "Ward wise Segregation",
      numerator: segrigatedCollectionInWard,
      denominator: scannedPropertiesInWard === 0 ? 1 : scannedPropertiesInWard,
      extraInfo:
        "Shows the number of properties scanned Vs number of properties where segregation happened for a particular ward for the selected date.",
    },
    {
      tabName: "Ward Wise Attendance",
      numerator: presentUserCount,
      denominator: totaluserCount,
      extraInfo:
        "Shows number of waste workers present for the particular ward for the selected date.",
    },
  ];

  const HandleOnMouseEnter = (tabName: string) => {
    setShowExtraInfo(tabName);
  };
  const HandleOnMouseLeave = () => {
    setShowExtraInfo("");
  };

  return (
    <div className="w-full mt-8 ml-2 h-1/3 ">
      <div className="flex justify-end mr-4">
        <CalenderSelctor calendar={calendar} setCalendar={setCalendar} />
      </div>
      <div className="border-2 border-t-0 bg-white">
        <div className="flex justify-between">
          {TabName.map((item, index) => {
            return (
              <div
                key={index}
                className={`h-12  border text-white w-1/3 cursor-pointer hover:bg-lightBlue hover:text-recity py-2 border border-black  ${
                  selectedTab === item.tabName
                    ? "bg-lightBlue text-recity"
                    : "text-white bg-recity"
                }`}
                onClick={() => setSelectedTab(item.tabName)}
                onMouseEnter={() => HandleOnMouseEnter(item.tabName)}
                onMouseLeave={HandleOnMouseLeave}
              >
                <h1 className="text-l font-medium text-center">
                  {item.tabName}
                  {` (${Math.round(item.numerator).toString()}/${
                    Math.round(item.denominator).toString() === "1"
                      ? "0"
                      : Math.round(item.denominator).toString()
                  }) (${Math.round(
                    !isNaN(
                      (Number(item.numerator) * 100) / Number(item.denominator)
                    )
                      ? (Number(item.numerator) * 100) /
                          Number(item.denominator)
                      : 0
                  )}%)`}{" "}
                </h1>
                <div
                  className={`relative text-sm rounded-lg bg-black text-white items-end mx-auto mt-2 py-4 ${
                    showExtraInfo !== item.tabName ? "hidden" : ""
                  }`}
                >
                  {item.extraInfo}
                </div>
              </div>
            );
          })}
        </div>

        <div>
          {selectedTab === "Ward wise Segregation" ? (
            <SegregationVsCollectionChart
              city={city}
              calendar={selectdDate}
              setSegrigatedCollectionInWard={setSegrigatedCollectionInWard}
              setScannedPropertiesInWard={setScannedPropertiesInWard}
            />
          ) : selectedTab === "Ward Wise Attendance" ? (
            <AttendanceChart
              city={city}
              calendar={selectdDate}
              setPresentUserCount={setPresentUserCount}
              setTotalUseCount={setTotalUseCount}
            />
          ) : (
            <ScannedCollectionChart
              city={city}
              calendar={selectdDate}
              setScannedPropertiesInWard={setScannedPropertiesInWard}
              setTotalPropertiesInWard={setTotalPropertiesInWard}
            />
          )}
        </div>
        <div className="hidden">
          <SegregationVsCollectionChart
            city={city}
            calendar={selectdDate}
            setSegrigatedCollectionInWard={setSegrigatedCollectionInWard}
            setScannedPropertiesInWard={setScannedPropertiesInWard}
          />
          <AttendanceChart
            city={city}
            calendar={selectdDate}
            setPresentUserCount={setPresentUserCount}
            setTotalUseCount={setTotalUseCount}
          />
          <ScannedCollectionChart
            city={city}
            calendar={selectdDate}
            setScannedPropertiesInWard={setScannedPropertiesInWard}
            setTotalPropertiesInWard={setTotalPropertiesInWard}
          />
        </div>
      </div>
    </div>
  );
};

export default WardWiseData;
