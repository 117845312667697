import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const usePropertyListData = ({city, filteredPropertyTypeArray, skip, limit, setTotalCount}:{city:string, filteredPropertyTypeArray:any[], skip:number, limit:number, setTotalCount:any}) => {

  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [propertyDetails, setPropertyDetails] = useState<any>({});
  const [propertyType,setPropertyType] = useState<string[]>([]);
  // useEffect(()=>{
  //   let tempArr:string[] = [];
  //     filteredPropertyTypeArray.forEach((item)=>{
  //       if(item.isChecked){
  //         tempArr.push(item?.type)
  //       }
  //     });
  //     setPropertyType(tempArr)
  // },[filteredPropertyTypeArray])
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);
    (async () => {
      // console.log("Inside data fetch",filteredPropertyTypeArray)
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/properties/all-properties?city=${city}&propertyType=${filteredPropertyTypeArray}&skip=${skip}&limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result.data.toalProperties);
        let propertyData = result?.data?.propertyList;
        let totalproperties = result?.data?.toalProperties;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
        setTotalCount(totalproperties);
        setPropertyList(propertyData);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, skip, limit, filteredPropertyTypeArray ]);


    const getPropertyDetails = async(id:string) => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/properties/property/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result.data.propertyDetails);
        let propertyDetails = result?.data?.propertyDetails;
        setPropertyDetails(propertyDetails);
        // let totalproperties = result?.data?.toalProperties;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
        // setTotalCount(totalproperties);
        // setPropertyList(propertyData);
        
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };


  return {  propertyList, propertyDetails, getPropertyDetails, loading, error };
};

export default usePropertyListData ;
