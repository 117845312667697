import React, { useEffect, useState } from "react";
import Navbar from "./component/Navbar";
import SideMenuBar from "./component/sideMenu/SideMenuBar";
import { Outlet } from "react-router-dom";
import useUserData from "./Data/userData";
import useCity from "./Data/cityData";
import { DropDown } from "./component/Ui-Elemnent/DropDown";
const Home = ({selectCity, setSelectCity}:{selectCity:string, setSelectCity:any}) => {
  // const [selectCity, setSelectCity] = useState("Select City");
  const [list, setList] = useState<any[]>([]);
  const { userDetails } = useUserData();
  const { cityList } = useCity();
  useEffect(() => {
    userDetails.roleId === 1 ? setList(cityList) : setList([userDetails.city]);
  }, [cityList, userDetails]);

  useEffect(() => {
    setSelectCity(list[0]);
  }, [list]);
  useEffect(()=>{
    // console.log("selectCity", selectCity)
  },[selectCity])
  const [selectedTab, setSelectedTab] = useState<string>("Insights");
  const [selectedSubTab, setSelectedSubTab] = useState<string>("");
  return (
    <div>
      <Navbar />

      <div className="flex w-full">
        <SideMenuBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedSubTab={selectedSubTab}
          setSelectedSubTab={setSelectedSubTab}
        />
       <div className='w-5/6 mt-4 relative'>
      <div className='flex justify-between mt-16  mx-8 '>
        <h3 className='text-3xl text-bold'>{selectedTab}</h3>
        <div className=''>
          <p className='text-md text-start mb-2'>City : {userDetails.roleId === 1 ? <DropDown list={list} selected={selectCity} setSelected={setSelectCity}/> : <span className='text-l p-2 bg-lightBlue cursor-pointer border border-gray rounded-md  '>{userDetails.city}</span> }
          {/* <CitySelection /> */}
          <span>
            
          </span>
          
          </p>
        </div>
      </div>

      <div className=''>
      <Outlet />
      </div>
    </div>
      </div>
    </div>
  );
};

export default Home;
