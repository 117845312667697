
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useMaterialTransferDetails = ({city, fromDate, toDate, setTotalAmountofWaste }:{city:string, fromDate:string, toDate:string, setTotalAmountofWaste:any}) => {

  
  const [materialTransferData, setMaterialTransferData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/materialTransfer?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result.data.materialTransferData);
        
        const types = Object.keys(result.data.materialTransferData);
        const westInKgs = types.map((type) => {
                return result.data.materialTransferData[type];
              });
        if (!didCancel) {
          const totalWaste = Number(result.data.materialTransferData.Dry) + Number(result.data.materialTransferData.Wet) + Number(result.data.materialTransferData.BioMedical) + Number(result.data.materialTransferData.Hazardous) + Number(result.data.materialTransferData.Mixed);
          // console.log(Number(result.data.materialTransferData.Dry) , Number(result.data.materialTransferData.Wet) , Number(result.data.materialTransferData.BioMedical) , Number(result.data.materialTransferData.Hazardous) , Number(result.data.materialTransferData.Mixed));
          
          setTotalAmountofWaste(totalWaste)
          setMaterialTransferData({
                labels: types,
      datasets: [
        {
          label: "Material Transfer",
          data: westInKgs,
          borderColor: "#Fefdff",
          backgroundColor: ['#Fdbfbf','#Bff1fd','#Fdf8bf', '#Bffdd0', "#Cabffd"],
        },
      ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {   materialTransferData, loading, error };
};

export default useMaterialTransferDetails ;
