import { useState } from "react";
import ImageViwer from "../ImageViwer";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import usePropertyData from "../data-fetch/property-data-fetch";
export default function ViewProperty(
//     {
//     setOpenpropertyDetails,
//     property
//   }: {
//     setOpenpropertyDetails: any;
//     property:any
//   }
  ) {
    const navigate = useNavigate();
    const params:any = useParams();
    const id:string = params.id || ""
    // console.log("params", id);
   
    const propertyDetails = usePropertyData({id});
    // console.log("propertyDetails", propertyDetails?.propertyDetails)
    let property = propertyDetails?.propertyDetails;
    const [openImage, setOpenImage] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>("")
    const handleViewPictureButton = (e:any, imgUrl:any) =>{
      e?.preventDefault();
      if(imgUrl){
        if(imgUrl)
        typeof imgUrl === "string"  ? setImgUrl(imgUrl) : setImgUrl(imgUrl[0]);
      
        setOpenImage(true);
      }else{
        toast("Image is not available.", {type:"error", theme:"colored"})
      }

    }
    const handleBack = (e:any)=>{
      e.preventDefault();
      navigate(-1)
    }
    return (
        <>
          <div className="m-20">
            <div className="p-y-20  rounded-md bg-slate flex justify-between">
              <div className="flex flex-row">
                <div className="m-2 uppercase font-semibold">ddn :</div>
                <div className="m-2 uppercase font-semibold">{property.DDNNumber}</div>
              </div>
              <div className="flex flex-row">
                <div className="m-2 ">Created by :</div>
                <div className="m-2">{property.userCreatedBy}</div>
              </div>
            </div>
            
          <form className="w-full mt-8">
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        Property Type
      </label>
      <div className="relative">
        <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded  leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-state" value={property?.area || "NA"}>
          <option>{property?.propertyType || "NA"}</option>
          {/* <option>Missouri</option>
          <option>Texas</option> */}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>  
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        Property owner name
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  disabled id="grid-last-name" type="text" placeholder="" value={property?.propertyOwnerName}/>
    </div>
    
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       property Latitude
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={property?.location?.coordinates[1]}/>
    </div>  
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        Property Longitude
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={property?.location?.coordinates[0]}/>
    </div>
    
  </div>

  <div className="flex flex-wrap -mx-3 mb-6">
  <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        Property Landmark
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={property.landmark || "N A"}/>
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       property Image
      </label>
      {/* <input className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={property?.propertyType}/> */}
      <button className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight" onClick={(e)=>handleViewPictureButton(e, property?.propertyPicture && property?.propertyPicture)}>
      View Property Image
      </button>
   </div>      
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       State
      </label>
      <input className="appearance-none block w-full bg-lightBlue text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={property?.state}/>
    </div>  
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        City
      </label>
      <input className="appearance-none block w-full bg-lightBlue text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={property?.city}/>
    </div>   
  </div>
  
  {/* <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        Password
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-password" type="password" placeholder="******************" />
      <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
    </div>
  </div> */}
  <div className="flex flex-wrap -mx-3 mb-6">
  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
       Ward Number
      </label>
      <div className="relative">
        <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-state" value={property.wardNumber}>
          <option>{property.wardNumber}</option>
         
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> 
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        Area
      </label>
      <div className="relative">
        <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-state" value={property?.area || "NA"}>
          <option>{property?.area || "NA"}</option>
          {/* <option>Missouri</option>
          <option>Texas</option> */}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
     
       
  </div>
 <div className="flex justify-center">
        <button className="bg-green p-4 m-4 text-white rounded uppercase" onClick={(e)=>{e.preventDefault()}}>Update Property</button>
        <button className="bg-recity p-4 m-4 text-white rounded uppercase" onClick={(e)=>{handleBack(e)}}>Back</button>
 </div>
</form>
</div>
{openImage && <ImageViwer setOpenImage={setOpenImage} imageUrl={imgUrl}/>}          
          <ToastContainer />
        </> 
    );
  }
  