
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useSagrigationDetails = ({city, fromDate, toDate, setSegrigatedProperties, setTotalProperties, setScannedProperties }:{city:string, fromDate:string, toDate:string, setSegrigatedProperties:any, setTotalProperties:any, setScannedProperties:any}) => {
  const [sagrigationData, setSagrigationData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/segregation?city=${city}&&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        const dates = Object.keys(result.data.collectionData);
        const propertiesCovered = dates.map((date) => {
                return result.data.collectionData[date].propertiesCovered;
              });
              const totalProperties = dates.map((date) => {
                return result.data.collectionData[date].totalProperties;
              });

              const totalSegrigation = dates.map((date) => {
                        return result.data.collectionData[date].totalSegregation;
                      });
              // console.log("propertiesCovered", totalProperties)
        if (!didCancel) {
          setSegrigatedProperties(totalSegrigation.reduce((a,b)=> a +b));
          setTotalProperties(totalProperties.reduce((a,b)=> a +b));
          setScannedProperties(propertiesCovered.reduce((a,b)=> a +b))
          setSagrigationData({
            labels: Object.keys(result.data.collectionData),
            datasets: [
              
              {
                label:  "Segregated Collection",
                data: totalSegrigation,//collectionData?.map((obj:any) => obj.collectionCount),
               borderColor: "#23418D",
               backgroundColor: "#23418D",
               maxBarThickness: 50
             },
             {
                label: "Scanned Collection",
                data: propertiesCovered,//collectionData?.map((obj:any) => obj.collectionCount),
                borderColor: "#FFE45C", 
                backgroundColor: "#FFE45C",
                maxBarThickness: 50
             },
             {
              label:  "Total No. of Properties",
              data: totalProperties,//collectionData?.map((obj:any) => obj.collectionCount),
             borderColor: "#ADD8E6",
             backgroundColor: "#4CC6DE",
             maxBarThickness: 50
           },
            ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return { sagrigationData, loading, error };
};

export default useSagrigationDetails ;