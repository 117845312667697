import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useWasteWorkerWiseData = ({city, date,  wardNumber, skip, limit, setTotalCount,  wwid}:{city:string, date:string, skip:number, limit:number, setTotalCount:any,  wardNumber: number | string, wwid: string}) => {

  const [wwData, setWWdata] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       console.log("date",date)
        const response = await fetch(
          `${BASEURL}/v1/reports/cluster-report/wasteWorker-wise?wardNo=${wardNumber}&city=${city}&date=${date}&id=${wwid}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data?.wasteWorkerList?.wasteWorkerArray);
        // const wards = Object.keys(result.data.clusterList);
        // // console.log("wards", wards)
        // const propertiesInfo = wards.map((index) => {
        //     return  {wardNo : result.data.clusterList[index].wardNo, totalClustersAvailable: result.data.clusterList[index].totalClustur, totalScannedClusters:Math.round(result.data.clusterList[index].totalScannedClusters/ dayDiff) , totalUnscannedClusters:Math.round(result.data.clusterList[index].totalClustur - Math.round(result.data.clusterList[index].totalScannedClusters/dayDiff)) , clusterScanningPercent: (100 * Math.round(result.data.clusterList[index].totalScannedClusters/ dayDiff) / result.data.clusterList[index].totalClustur).toFixed(2) };
        //   });
          
        // console.log("propertiesInfo", propertiesInfo);
        setTotalCount(result?.data?.wasteWorkerList?.wasteWorkerArray?.length)
        setWWdata(result?.data?.wasteWorkerList?.wasteWorkerArray);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, date]);

  return {   wwData, loading, error };
};

export default useWasteWorkerWiseData ;
