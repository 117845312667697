import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useTspPropertyListData = ({city, skip, limit, setTotalCount}:{city:string, skip:number, limit:number, setTotalCount:any}) => {

  const [tspPropertyList, setTspPropertyList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({})
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/properties/tsp?city=${city}&skip=${skip}&limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data?.tSPList?.propertiList);
        let propertyData = result?.data?.tSPList?.propertiList;
        let totalproperties = result?.data?.tSPList?.totalCount;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
        setTotalCount(totalproperties);
        setTspPropertyList(propertyData);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city,skip, limit]);


  return { tspPropertyList, loading, error };
};

export default useTspPropertyListData ;
