import React,{useState, useEffect, useCallback} from "react";
import useFeesCollectionData from "./data-fetch/fetch-feesCollection";
import ScaleLoaderItem from "../Ui-Elemnent/ScaleLoader";

import DateRangeSelector from '../Ui-Elemnent/DateRange'
import format from 'date-fns/format';
import { CSVLink } from "react-csv";
import RevisitScheduled from "./RevisitSchedule";
const rowHeading = [{heading: "sr. no." ,  isChecked:true},  {heading:"PU DDN",  isChecked:true}, {heading:"Owner's Name", key: 'ownersname' , isChecked:true}, {heading:"Contact No.", isChecked:true}, {heading:"Ward no.",key: 'wardNo', isChecked:true},{heading:"AREA",  isChecked:true}, {heading:"Property status", isChecked:true}, {heading:"Collection status", isChecked:true},{heading:"Payment Type", isChecked:true}, {heading:"Payment status", isChecked:true},{heading:"Amount",key: 'amount', isChecked: true} ]


export default function Table({city,  skip, limit, setTotalCount, setCurrentPage}:{city:string, skip:number, limit:number, setTotalCount:any, setCurrentPage:any}) {
    
     ////// Date Range Selector
     const [dayDiff, setDaydiff] =useState<any>()
     const today = new Date()
     const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [range, setRange] = useState<any[]>([
       {
         endDate: new Date(),
         startDate: today.setDate(today.getDate()-6),
         key: 'selection', 
       }
     ]);
 
     useEffect(()=>{
 
         setToDate(format(range[0].endDate, "yyyy-MM-dd"))
         setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
     
         setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
     
       },[range, toDate, fromDate]);
     ///////////////

    const { feesCollectionData, loading} = useFeesCollectionData({city:city,toDate:toDate, fromDate:fromDate,skip:skip, limit:limit, setTotalCount:setTotalCount});
    // console.log("feesCollectionData",feesCollectionData);
    
    useEffect(()=>{
        setCurrentPage(1)
     },[range, toDate, fromDate])
    const [ucfList, setUcfList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);
    
    const [revsitSchedule, setRevisitSchedule] = useState<any>({})
    const [activeDDN, setActiveDDN] = useState<any>("");
    const [showReschedule, setShowReschedule] = useState<boolean>(false)
    type Data = typeof feesCollectionData;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(feesCollectionData)
        
    },[ feesCollectionData])

    const headers = [
        { label: 'PU DDN', key: 'ddn' },
        { label: 'Owners Name', key: 'ownersname' },
        { label: 'Contact No.', key: 'contactNo' },
        { label: 'Ward No.', key: 'wardNo' },
        { label: 'Area', key: 'area' },
        { label: 'Property Status', key: 'propertyStatus' },
        { label: 'Collection Status', key: 'collectionStatus' },
        { label: 'Pament Type', key: 'paymentType' },
        { label: 'Payment Status', key: 'paymentStatus' },
        { label: 'Amount', key: 'paymentAmount' },
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
   
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: feesCollectionData, sortKey, reverse : sortOrder === 'desc'}),[feesCollectionData, sortKey, sortOrder])

      useEffect(()=>{
        setData(sortedData())
    },[sortedData]);

      useEffect(()=>{
        setUcfList(data.slice(skip, (skip + limit)))
    },[skip, limit, data, sortedData]);
    
    

    const handleChange = ( value:string)=>{
        setSearchText(value)
           
         }   

        useEffect(()=>{
            filterData(searchText)
        },[searchText])

        const filterData = (value:string)=>{
            const lowerCaseValue =  value.toLowerCase().trim();
            // console.log("lowerCaseValue",lowerCaseValue);
            
            if(!lowerCaseValue){
                setData(sortedData())
            }else {
                const filteredData = sortedData().filter(item =>{
                    // console.log("item", item)
                    return Object.keys(item).some(key =>{
                       
                        return item?.[key]?.toString().toLowerCase().includes(lowerCaseValue)
                    })
                });
                // console.log("filteredData",filteredData);
                
                setData(filteredData)
                // console.log(data);
                
            }
        }
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        } 
        
        const handleRevisit = (obj:any)=>{
            setRevisitSchedule(obj)
            setShowReschedule(true)
        }
        const handleMouseLeave = ()=>{
            setRevisitSchedule("");
        }      
        // console.log(revsitSchedule);
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>

                    <div className='flex justify-end  '>
                    { <DateRangeSelector range={range} setRange={setRange}  />}

                     </div>

                     <div className="flex items-center border border-gray rounded-md ml-4" >
                        <div className="relative">
                        <CSVLink data={sortedData()} filename={`Fees collection list_${city}_${fromDate}_${toDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download 
                                        
                                    </div>
                                </span>
                            </button>
                            </CSVLink>
                        </div>
                    </div>

                </div>
               
                
                <div className="p-1.5 inline-block  align-middle">
                    <div className="overflow-visible border rounded-lg ">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-6 py-3 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.isChecked &&  item.heading } 
                                    </th>
                                            )
                                        })
                                    }
                                    
                                    
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    ucfList.map((item, index)=>{
                                        // console.log("item", item);
                                        
                                        return(
                                            <tr key={index}>

                                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                                {filteredColumn[0].isChecked && skip + index + 1}
                                            </td>
                                            <td className="px-2 py-4 text-sm text-gray-800 whitespace-nowrap">
                                                {filteredColumn[1].isChecked && item?.ddn}
                                            </td>
                                            <td className=" py-4 text-sm text-gray-800 uppercase whitespace-nowrap">
                                            {filteredColumn[2].isChecked && item?.ownersname}
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {filteredColumn[3].isChecked && (item?.contactNo || 'N A')}
                                                
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked && item?.wardNo}
                                           
                                       </td>
                                       <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                               
                                               {filteredColumn[5].isChecked && (item?.area  || "NA")}
                                           
                                       </td>
                                       <td >
                                        <button className={`${item?.propertyStatus === "REVISIT" ? "px-6 py-4 text-sm text-green font-medium  whitespace-nowrap cursor-pointer": "px-6 py-4 text-sm font-medium  whitespace-nowrap"} `} onClick={()=>{handleRevisit(item)}} disabled={ item?.propertyStatus === "REVISIT" ? false : true}>
                                            
                                               { item?.propertyStatus }
                                        </button>
                                              
                                           
                                       </td>
                                            <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                                
                                                    { item?.collectionStatus}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                                
                                                    { item?.paymentType}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                                
                                                    { item?.paymentStatus}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                                
                                                    { item?.paymentAmount}
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
            <div className="absolute justify-center z-20 ">
                    {showReschedule && <RevisitScheduled showReschedule={showReschedule} setShowReschedule={setShowReschedule}  data={revsitSchedule} />}
                </div>
            {/* {openFeesCollectionModel &&   
        <GetProperties openFeesCollectionModel={openFeesCollectionModel} setOpenFeesCollectionModel={setOpenFeesCollectionModel} city={city}/>
            } */}
        </div>
    );
}