import React, { useEffect, useState } from 'react'
import useUserLogin from '../../Data/userLogin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function LoginForm() {

  const [logedIn, setLogedIn] = useState({})
  const [errorMsg, setErrorMsg] = useState("")
  const [hasError, setHasError] = useState(false)
  const {login} = useUserLogin()

  const submitForm = async (event:any)=>{
    event.preventDefault();
    setHasError(false)
    let data = JSON.stringify({
      username: event.target.username.value,
      password: event.target.password.value
    })
    
   let loginUser = await login(data)
   if(!loginUser.success){
    setErrorMsg(loginUser.error);
    setHasError(true)
    toast(loginUser.error, {type:"error", theme:"colored"});
   }else{
    toast("Login Successful", {type:"success", theme:"colored"});
     setLogedIn(loginUser.data)
   }
   
    
  }
  return (
    <div>
        <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
  <div className="w-full max-w-md space-y-8">
    <div>
      {/* <img className="mx-auto h-24 w-auto" src="/recity_logo.png" alt="Recity" /> */}
      <h2 className=" text-center text-3xl font-bold tracking-tight text-recity">Please Sign in to your account</h2>
    {/* <div className={`text-red py-4 bg-slate ${hasError ? "" : "hidden"}`}>{errorMsg}</div> */}
    </div>
    <form className="mt-8 space-y-6" onSubmit={(e)=>submitForm(e)}>
      <input type="hidden" name="remember" value="true" />
      <div className="-space-y-px rounded-md shadow-sm">
        <div>
          <label  className="sr-only">Username</label>
          <input id="username" name="username" type="text"  required className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Username" />
        </div>
        <div>
          <label  className="sr-only">Password</label>
          <input id="password" name="password" type="password"  required className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Password" />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
          <label  className="ml-2 block text-sm text-gray-900">Remember me</label>
        </div>

        {/* <div className="text-sm">
          <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
        </div> */}
      </div>

      <div>
        <button type="submit" className="group relative flex w-full justify-center rounded-md border border-transparent bg-recity py-2 px-4 text-sm font-medium text-white hover:bg-darkBlue focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
           
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
            </svg>
          </span>
          Login to Dashboard
        </button>
      </div>
    </form>
  </div>
</div>
<ToastContainer />
    </div>
  )
}

export default LoginForm