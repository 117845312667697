import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const refOne = useRef<any>(null)
    const navigate = useNavigate()
    const [open , setOpen] = useState(false)
    const handleIconClick = (e:any)=>{
        e.preventDefault();
        setOpen(!open)
    }
    const handleLogout = (e:any) =>{
        e.preventDefault();
        localStorage.removeItem("userInfo")
        localStorage.removeItem("auth-token")
        setOpen(!open)
        navigate("/login")
    }
    useEffect(()=>{
        document.addEventListener("click", hideOnClickOutside, true)
      },[])
    const hideOnClickOutside =(e:any)=>{
        if(refOne.current && !refOne.current.contains(e.target)){
          setOpen(false)
        }
        
      }
  return (
    <>
    <header className="w-full fixed  shadow-lg bg-white items-center h-16 rounded-2xl z-40">
    <div className="relative z-20 flex flex-col justify-center h-full px-3 mx-auto flex-center">
        <div className="relative items-center pl-1 flex w-full lg:max-w-68 sm:pr-2 sm:ml-0">
            <div className="container relative left-0 z-50 flex w-3/4 h-auto h-full">
                <div className="relative flex items-center w-full lg:w-64 h-full group">
                    <div className="absolute z-50 flex items-center justify-center block w-auto h-10 p-3 pr-2 text-sm text-gray-500 uppercase cursor-pointer sm:hidden">
                        <svg fill="none" className="relative w-5 h-5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                            <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z">
                            </path>
                        </svg>
                    </div>
      <img className="mx-auto h-12  w-auto" src="/recity_logo.png" alt="Recity" />
                    </div>
                </div>
                <div className="relative p-1 flex items-center justify-end w-1/4 ml-5 mr-4 sm:mr-0 sm:right-auto">
                    <button  className="block relative" onClick={(e)=>{handleIconClick(e)}}>
                        <img alt="profil" src="user_img.png" className="mx-auto object-cover rounded-full h-10 w-10 "/>
                    </button>
                </div>
            </div>
        </div>  
        <div  className='flex items-center justify-end'>   
    <div ref={refOne} className={`w-56 h-24 bg-darkGray rounded rounded-md border flex items-center justify-end p-8  ${open ? "": 'hidden'}`}>
            <button className='bg-recity p-2 rounded rounded-lg text-white' onClick={(e)=>{handleLogout(e)}}>Logout</button>
        </div>
        </div>
    </header>
    </>
  )
}

export default Navbar