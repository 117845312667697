import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useFeesInsights = ({city, toDate, fromDate, }:{city:string, toDate:string,fromDate:string}) => {

  const [feesInsightDetails, setFeesInsightDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/fee-collections/insights?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        const feesInsightData = result.data;
        
        // console.log("feesInsightData", feesInsightData);
       
        setFeesInsightDetails(feesInsightData);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {   feesInsightDetails, loading, error };
};

export default useFeesInsights ;
