
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;

const useAttendanceDetails = ({city, fromDate, toDate,setTotalUser, setPresentUser }:{city:string, fromDate:string, toDate:string, setTotalUser:any, setPresentUser:any}) => {

  
  const [attendanceData, setAttendanceData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [{
        label: "Attendance",
        data: [0],
        borderColor: "#ADD8E6",
        backgroundColor: "#ADD8E6",
        maxBarThickness: 50
    }, ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/attendance?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
             
        const dates = Object.keys(result.data.attendanceData);
        const presentUser = dates.map((date) => {
                return result.data.attendanceData[date].present_user_count;
              });
        const totalUserCount = dates.map((date) => {
                return result.data.attendanceData[date].total_user_count;
              });
              // console.log("attendance",presentUser,totalUserCount );
        if(!dates.length){
          setPresentUser(0);
          setTotalUser(0);
          setAttendanceData({
            labels: dates,
            datasets: [
              {
                  label: "Attendance",
                  data: [0],
                  borderColor: "#ADD8E6",
                  backgroundColor: "#ADD8E6",
                  maxBarThickness: 50
              },
            ],
          });
        }else{
          
          if (!didCancel) {
            setPresentUser(presentUser.reduce((a,b)=>a+b));
            setTotalUser(totalUserCount.reduce((a,b)=> a+b));
            setAttendanceData({
              labels: dates,
              datasets: [
                {
                    label: "Attendance",
                    data: presentUser,
                    borderColor: "#ADD8E6",
                    backgroundColor: "#ADD8E6",
                    maxBarThickness: 50
                },
              ],
            });
            didCancel =true
            }
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {   attendanceData, loading, error };
};

export default useAttendanceDetails ;
