import { useEffect, useState, useCallback } from "react";
const useUserData =()=>{
    const userInfo = localStorage.getItem("userInfo")
   
  const [userDetails, setUserDetails] = useState<any>({})
  useEffect(()=>{
    if(userInfo){
        setUserDetails(JSON.parse(userInfo))
    }
  },[userInfo])

//  console.log("===>user Details", userDetails);
 
    return {userDetails}
}

export default useUserData;