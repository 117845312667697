import React, { useState, useEffect } from 'react'
import useFeesInsights from './data-fetch/fetch-fees-insight';
import format from 'date-fns/format'
import DateRangeSelector from '../Ui-Elemnent/DateRange';
import ScaleLoaderItem from "../Ui-Elemnent/ScaleLoader";

const FmsInsights = ({city}:{city:string}) => {
    // const [selected, setSelected] = useState<string>("fees")
    const today = new Date();
    const [dayDiff, setDaydiff] =useState<any>()
    const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const { mrfDetails,loading} = useFeesInsights({city:city, toDate:toDate, fromDate:fromDate});
    console.log("mrfDetails", mrfDetails);
    const [range, setRange] = useState<any[]>([
        {
          startDate: today.setDate(today.getDate()-6),
          endDate: new Date(),
          key: 'selection', 
        }
      ])

    useEffect(()=>{

      setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
      setToDate(format(range[0].endDate, "yyyy-MM-dd"))
      setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
      // console.log("dates:--->",toDate,fromDate,dayDiff);
       
    },[range, toDate, fromDate]);

  //   useEffect(()=>{

  //     console.log(toDate, fromDate)
  // },[toDate, fromDate])
  return (
    <div className='w-full ml-2 h-1/3 bg-white p-2 rounded-lg'>
     <div className='flex justify-between mr-4'>
      <div className="text-3xl font-medium text-start ml-6">MRF Operation insights</div>
       <DateRangeSelector range={range} setRange={setRange}/>
      </div>
      
      {/* <div className="flex justify-between w-1/5 cursor-pointer m-4">
        <div className={`${selectedTab === 'Collected Fees Stats'? "text-recity underline text-lg" : "text-black text-lg"}`} onClick={()=>setSelectedTab('Collected Fees Stats')}>Fees Collection Stats</div>
        <div className={`${selectedTab === 'User Stats'? "text-recity underline text-lg" : "text-black text-lg"}`} onClick={()=>setSelectedTab('User Stats')}>User Stats</div>
      </div> */}

    {/* {selectedTab === 'Collected Fees Stats' ? <FeesCollectionStat feesInsightDetails={feesInsightDetails}/> : <UserStat feesInsightDetails={feesInsightDetails}/>} */}
    
    {loading ? <ScaleLoaderItem loading={loading}/> :
    <div>
    <FeesCollectionStat mrfDetails={mrfDetails}/> 
     {/* <UserStat feesInsightDetails={feesInsightDetails}/> */}
     </div>
    }
    </div>
    
  )
}

function FeesCollectionStat({mrfDetails}:{mrfDetails:any}){
  return(
    <div className="grid grid-cols-4 gap-8 m-4 w-3/4">
      <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
        <div className=" text-sm">Total No. Of Trips </div>
        <div className='text-3xl font-500'> {mrfDetails.tripCount}</div>
      </div>
      
      <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
        <div className=" text-sm">Total Inward Quantity of MSW DW</div>
        <div className='text-3xl font-500'> {mrfDetails.receivedQuantity}</div>
      </div>
      {/* <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
        <div className=" text-sm">Total Fees Collected</div>
        <div className='text-3xl font-500'> {feesInsightDetails.toatlFeesCollection}</div>
      </div> */}

    </div>
  )
}

// function UserStat({feesInsightDetails}:{feesInsightDetails:any}){
//   return(
//     <div className="grid grid-cols-4 gap-8 m-4 w-3/4">
//       <div className="bg-yellow-50  rounded rounded-lg p-2 text-start">
//         <div className=" text-sm"> Users Paid Fees By Cash</div>
//         <div className='text-3xl font-500'> {feesInsightDetails.noOfUserPaidByCash}</div>
//       </div>
      
//       <div className="bg-yellow-50  rounded rounded-lg p-2 text-start">
//         <div className=" text-sm"> Users Paid Fees By UPI</div>
//         <div className='text-3xl font-500'> {feesInsightDetails.noOfUserPaidByUPI || 0}</div>
//       </div>
//       <div className="bg-yellow-50  rounded rounded-lg p-2 text-start">
//         <div className=" text-sm">Total Users Paid Fees</div>
//         <div className='text-3xl font-500'> {feesInsightDetails.noOfUserPaidByCash + feesInsightDetails.noOfUserPaidByUPI}</div>
//       </div>

//     </div>
//   )
// }
export default FmsInsights