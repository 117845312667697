import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useFeesInsights = ({ city, toDate, fromDate, }: { city: string, toDate: string, fromDate: string }) => {

  const [mrfDetails, setMrfDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  
  useEffect(() => {
    let didCancel = false;
    // console.log("+++++++++++++++++++++++")
    setError(null);

    (async () => {
      try {
        setLoading(true);
        const requestData = {
          facilityId: '18',
          startDate: fromDate,
          endDate: toDate,
          vendorTypeId: '6',
        };

        const response = await fetch(
          `https://api.cetraces.in/api/inward-management/inwards/count-trip`,
          {
            headers: {
              "Content-Type": "application/json",
              
            },
            body: JSON.stringify(requestData),
            method:"POST"

          }
        );

        const result = await response.json();
        const mrfData = result.data;

       // console.log("feesInsightData", feesInsightData);

        setMrfDetails(mrfData);

        if (!didCancel) {

          didCancel = true
        }
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {  mrfDetails, loading, error };
};

export default useFeesInsights;
