import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const usePropertyDetails = () => {
    
//   const [propertyDetails, setPropertyDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({})
  // useCallback(()=>{

  // },[])
  
  const getPropertyDetails =  async (ddn:string) => {
    try {
      setLoading(true);
     
      const response = await fetch(
        `${BASEURL}/v1/properties/survey-details?ddn=${ddn}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
    //   console.log("result", result?.data);
      // let propertyData = result?.data?.tSPList?.propertiList;
      // let totalproperties = result?.data?.tSPList?.totalCount;
      // const propertyDDN = Object.keys(result.data.defaulterList);
      // const propertiesInfo = propertyDDN.map((ddn) => {
      //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
      //   });
        
      // console.log("propertyDDN", propertyDDN);
        return result?.data;

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { getPropertyDetails, loading, error };
};

export default usePropertyDetails ;
