import React from 'react'
import {Line} from 'react-chartjs-2'
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js';
 
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,)
const LineChart = ({chartData, option} : {chartData:any, option:any}) => {
    // console.log("chartData", chartData);
    
  return (
    <div className='w-full h-96'>

        <Line data={chartData}  options={option}/>
    </div>
  )
}

export default LineChart