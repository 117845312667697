import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useAttendanceData = ({city, selectdDate, skip, limit, setTotalCount}:{city:string, selectdDate:string, skip:number, limit:number, setTotalCount:any}) => {

  const [attendanceData, setAttendanceData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);
    
    (async () => {
      try {
        setLoading(true);
        // http://localhost:8001/v1/attendance/list?city=mathura&to=2023-12-30&from=2023-12-22
        const response = await fetch(
          `${BASEURL}/v1/attendance/list?city=${city}&date=${selectdDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
        const attendance = Object.keys(result.data.attendanceList);
        // const propertiesInfo = attendance.map((ddn) => {
        //     return  { name:result.data.defaulterList[ddn].PUDDN};
        //   });
          
        // console.log("attendance", attendance);
        setTotalCount(attendance.length)
        setAttendanceData(result.data.attendanceList);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, selectdDate]);

  return {  attendanceData, loading, error };
};

export default useAttendanceData ;
