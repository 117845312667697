import React from 'react'
import LoginForm from './LoginForm'

function LoginPage() {
  return (
    <div className='bg-slate h-screen relative flex  '>
    <div className='w-1/3 my-auto ml-16 '>
      <img src="/recity_logo.png" alt="Recity"/>
    </div>
    <div className='relative my-auto'>
      <div className='inline-block  ml-72  rounded-3xl p-8'>
      <h1 className='text-4xl text-gray-400 '>Welcome back,</h1>
        <LoginForm />
      </div>
    </div>
    </div>
  )
}

export default LoginPage