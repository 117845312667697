import React,{useState, useEffect, useCallback} from "react";
import useClusterData from "../data-fetch/fetch-combined-cluster-reports";
import useClusterInsightsData from "../data-fetch/clusterInsights";
import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
// import FilterComponent from "../FilterComponent";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import format from 'date-fns/format';
import { useNavigate } from "react-router-dom";
import CalenderSelctor from "../../Ui-Elemnent/Calender";
import { DropDown } from "../../Ui-Elemnent/DropDown";

const rowHeading = [{heading:"Cluster DDN", key:'wardNo',  isChecked:true}, {heading:"Ward No.", key: 'totalClustersAvailable' , isChecked:true}, {heading:"Scan Status", key: 'Area/Location Name', isChecked:true},{heading:"Area/Location Name", key: 'Area/Location Name', isChecked:true}, {heading:"Assigned WW",key: 'totalUnscannedClusters', isChecked:true}, {heading:"Assigned WW Name",key: 'totalUnscannedClusters', isChecked:true}, {heading:"Scanned WW", key: 'clusterScanningPercent',isChecked:true}, {heading:"Scanned WW name",key: 'totalUnscannedClusters', isChecked:false},]


export default function Table({city,  skip, limit, setTotalCount, range, setRange}:{city:string, skip:number, limit:number, setTotalCount:any, range:any, setRange:any}) {
    const navigate = useNavigate();
     ////// Date Range Selector
     const today = new Date()
     
     const [calendar, setCalendar] = useState<any>(today);
     const [selectdDate, setSelectedDate] = useState(format(new Date(), "dd/MM/yyyy"));

     useEffect(()=>{
        setSelectedDate(format(new Date(calendar), "dd/MM/yyyy"))
     },[calendar])
     ///////////////
     

    const {clustersData, loading} = useClusterData({city:city, date:selectdDate, setTotalCount:setTotalCount});
    console.log("clustersData",clustersData);
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [clusterList, setClusterList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);

    // Filter Status
     const options = ["All","Scanned", "Un-Scanned", "UnAssignScanned"];
    const [selectedStatus, setSelectedStatus] = useState(options[0])

    ///
    // Filter Data According to status
    useEffect(()=>{
        if(selectedStatus=== "Scanned"){
            let tempData = clustersData.filter((item)=> item.scanStatus === "Yes");
            setTotalCount(tempData.length)
            setData(tempData)
        }
        if(selectedStatus=== "Un-Scanned"){
            let tempData = clustersData.filter((item)=> item.scanStatus === "No");
            setTotalCount(tempData.length)
            setData(tempData)
        }
        if(selectedStatus=== "UnAssignScanned"){
            let tempData = clustersData.filter((item)=>item.scanStatus === "Yes" && item?.assignWW !== item?.scannedWW);
            setTotalCount(tempData.length)
            setData(tempData)
        }
        if(selectedStatus=== "All"){
            setTotalCount(clustersData.length)
            setData(clustersData)
        }
    },[selectedStatus])
    
    useEffect(()=>{
        setSelectedStatus("All");
        // setTotalCount(clustersData.length)
    },[city, selectdDate])
    type Data = typeof clustersData;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(clustersData)
        
    },[ clustersData])

    const headers = [{label:"Cluster DDN", key:'clusterDDN',  }, {label:"Ward No.", key: 'wardNumber' , }, {label:"Scan Status", key: 'scanStatus', },{label:"Area/Location Name", key: 'areaName', }, {label:"Assigned WW",key: 'assignWW', }, {label:"Assigned WW Name",key: 'name', }, {label:"Scanned WW", key: 'scannedWW',}]
    ;
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
   
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: clustersData, sortKey, reverse : sortOrder === 'desc'}),[clustersData, sortKey, sortOrder])

      ///
      useEffect(()=>{
        setData(sortedData())
    },[sortedData]);

      useEffect(()=>{
        setClusterList(data.slice(skip, (skip + limit)))
    },[skip, limit, data, sortedData]);
    
    

    const handleChange = ( value:string)=>{
        setSearchText(value)
           
         }   

        useEffect(()=>{
            filterData(searchText)
        },[searchText])

    const filterData = (value:string)=>{
        const lowerCaseValue =  value.toLowerCase().trim();
        // console.log("lowerCaseValue",lowerCaseValue);
        
        if(!lowerCaseValue){
            setData(sortedData())
        }else {
            const filteredData = sortedData().filter(item =>{
                // console.log("item", item)
                return Object.keys(item).some(key =>{
                   
                    return item[key].toString().toLowerCase().includes(lowerCaseValue)
                })
            });
            // console.log("filteredData",filteredData);
            
            setData(filteredData)
            // console.log(data);
            
        }
    } 
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        }   
        
       
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    {/* <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center border border-gray rounded-md mx-4" >
                        <div className="relative">
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" onClick={e => setOpenFilter(!openFilter)}>
                                <span className="relative inline-flex items-center px-3 py-3  space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-3 h-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Filters
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    */}
                    <div className='flex justify-end  '>
                    {/* { <DateRangeSelector range={range} setRange={setRange}  />} */}

                    {<CalenderSelctor calendar={calendar} setCalendar={setCalendar} />}
                    {<FilterComponent options={options} selected={selectedStatus} setSelected={setSelectedStatus} />}

                    <div className="flex items-center border border-gray rounded-md mr-4 h-12" >
                        <CSVLink data={clusterList?.length !== 0 && data !== undefined ? data : ''} filename={`Clusture_Report_${city}_${selectedStatus}_${selectdDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download    
                                    </div>
                                </span>
                            </button>
                            </CSVLink>
                    </div>
                </div>



                </div>
                {/* <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent options={options} selected={selected} setSelected={setSelected} />}
                </div> */}
                                <div className="">
                    <ClusterCollectionStat city={city} date={selectdDate}/>
                </div>
                <div className="p-1.5 w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-4 py-2 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.isChecked &&  item.heading }
                                    </th>
                                            )
                                        })
                                    }
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    clusterList.map((item, index)=>{
                                        
                                        return(
                                            <tr key={index}>
                                                      
                                            <td className="py-2 text-md text-recity whitespace-nowrap underline">
                                               <button className="font-bold">

                                                {filteredColumn[0].isChecked && item?.clusterDDN}
                                               </button>
                                               
                                            </td>
                                            <td className=" py-2 text-sm text-gray-800 whitespace-nowrap">
                                            {filteredColumn[1].isChecked && item?.wardNumber}
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                                    {filteredColumn[2].isChecked && item.scanStatus}
                                                
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[3].isChecked && (item?.areaName || "N A" )}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[3].isChecked && (item?.assignWW || "N A" )}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked && (item?.name || "N A" )}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked && (item?.scannedWW || "N A")}
                                           
                                            </td>
                                            {/* <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked && item?.scanned_cluster?.[0]?.username }
                                           
                                            </td> */}
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
        </div>
    );
}


function ClusterCollectionStat({city, date}:{city:string, date:string}){
    const {clustersInsightsData}:any = useClusterInsightsData({city, date})
    return(
      <div className="grid grid-cols-4 gap-8 m-4 w-3/4">
        <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
          <div className=" text-sm">Total Cluster</div>
          <div className='text-3xl font-500'> {clustersInsightsData.totalCluster}</div>
        </div>
        
        <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
          <div className=" text-sm">Total Scanned Clusters</div>
          <div className='text-3xl font-500'> {clustersInsightsData.scanedCluster}</div>
        </div>
        <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
          <div className=" text-sm">Total Unscanned Clusters	</div>
          <div className='text-3xl font-500'> {clustersInsightsData.unscannedCluster}</div>
        </div>
  
      </div>
    )
  }

  const FilterComponent = ({options, selected, setSelected}:{options:string[], selected:string, setSelected:any })=>{
    // const options = ["All","Scaned", "Un-Scaned", "UnAssignScaned"];
    // const [selected, setSelected] = useState(options[0])
    return(

        <div className="z-2 mx-2">
            <DropDown list={options} setSelected={setSelected} selected={selected}/>
        </div>
    )
  }