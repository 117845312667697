
const BASEURL = process.env.REACT_APP_BASE_URL

const useUpdateVehicleData =()=>{
  
  // const navigate = useNavigate();

  const getClusterList = async ({city}: {city:string}) => {
    try {
      // setLoading(true);
     
      const response = await fetch(
        `${BASEURL}/v1/insights/cluster-cordinate?city=${city}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
     
      let allCluster = result?.data?.clusters;
      
      return allCluster
      
      
    } catch (err) {
      console.log(err)
    }
  };
      
  const getVehicleDetails = async ({id}: {id:string}) => {
    try {
      // setLoading(true);
     
      const response = await fetch(
        `${BASEURL}/v1/vehicle/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
     
      let vehicleDetails = result?.data[0];
      console.log("vehicledetails", vehicleDetails)
      
      return vehicleDetails;
      
      
    } catch (err) {
      console.log(err)
    }
  };
    const addVehicle = async (p_body:any)=>{
      
        const res = await fetch(BASEURL + "/v1/vehicle", {
            body: p_body ,
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          });
          const result = await res.json()
          console.log("rsult after adding vehicle", result)
      
          return result
    }

    const updateVehicle = async (id:string, p_body:any)=>{
      
        const res = await fetch(`${BASEURL}/v1/vehicle/${id}`, {
            body: p_body,
            mode: "cors",
            headers: {
            //   Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
          });
          const result = await res.json()
      
          return result
    }

    
    return {addVehicle, updateVehicle, getClusterList, getVehicleDetails}
}

export default useUpdateVehicleData;