import React,{useEffect, useState} from 'react';
import format from 'date-fns/format';
import DateRangeSelector from '../Ui-Elemnent/DateRange'
import useUserData from '../../../src/Data/userData';
// import { BsDownload } from 'react-icons/bs';
import {CSVLink} from 'react-csv';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import  useProjectSummaryData from "./Sheet-data-fetch/fetch-project-summaryData";
import usePropertySurveyData from './Sheet-data-fetch/fetch-propertySurveyData';
import EmaiPrompt from './EmailPromt';
import useClusterReportData from './Sheet-data-fetch/fetch-clusterReport';
const Sheets = [{displayName: "Project Summary", link:""},{displayName: "Property Survey", link:""} ]


const ReportSheet = ({city}:{city:string}) => {
const {userDetails} = useUserData();

const {clusterReportSheet} = useClusterReportData()
  // DateRange
  const [dayDiff, setDaydiff] =useState<any>()
  const today = new Date()
  const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [range, setRange] = useState<any[]>([
    {
      endDate: new Date(),
      startDate: today.setDate(today.getDate()-6),
      key: 'selection', 
    }
  ]);

  useEffect(()=>{
 
    setToDate(format(range[0].endDate, "yyyy-MM-dd"))
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"))

    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);

  },[range, toDate, fromDate]);
//
const {projectSummaryData} = useProjectSummaryData({city:city,fromDate:fromDate, toDate:toDate })
const {propertySurvey} =  usePropertySurveyData();
const [selectedSheet, setSelectedSheet] = useState<string>("");
const [openPrompt, setOpenPrompt] = useState<boolean>(false)
const [emails, setEmails] = useState<string[]>([]);

const addEmails = ()=>{
  if(!emails.length){
    emails.push(userDetails.email)
  }
  setOpenPrompt(true)
}
const handlePropertySurveyDownload = ()=>{
  if(!emails.length){
    emails.push(userDetails.email)
  }
 propertySurvey({city:city, email:emails});
 toast("Sheet Will be sent to your email shortly!!")   
}

const handleClusterReportDownload = ()=>{
  if(!emails.length){
    emails.push(userDetails.email)
  }
  clusterReportSheet({city:city, email:emails,fromDate:fromDate, toDate:toDate });
  toast("Sheet Will be sent to your email shortly!!")   
 }


 
  return (
    <div className='mt-12 mx-6'>
         <div className='flex justify-end'>
         <button
                      className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-lg px-6 py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={addEmails}
                    >
                      <div className='flex flex-row'>

                      <svg className='fill-current text-white ' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M474-486q26-32 38.5-66t12.5-79q0-45-12.5-79T474-776q76-17 133.5 23T665-631q0 82-57.5 122T474-486Zm216 326v-94q0-51-26-95t-90-74q173 22 236.5 64T874-254v94H690Zm110-289v-100H700v-60h100v-100h60v100h100v60H860v100h-60Zm-485-32q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM0-160v-94q0-35 18.5-63.5T68-360q72-32 128.5-46T315-420q62 0 118 14t128 46q31 14 50 42.5t19 63.5v94H0Zm315-381q39 0 64.5-25.5T405-631q0-39-25.5-64.5T315-721q-39 0-64.5 25.5T225-631q0 39 25.5 64.5T315-541ZM60-220h510v-34q0-16-8-30t-25-22q-69-32-117-43t-105-11q-57 0-104.5 11T92-306q-15 7-23.5 21.5T60-254v34Zm255-411Zm0 411Z"/></svg>
                     <span className='pl-2'>Add Email</span>  
                      </div>
                    </button>
               <DateRangeSelector range={range} setRange={setRange}  />
         </div>
         <div className='grid grid-cols-3 gap-1 bg-zinc h-screen mt-6'>
          {/* {
            Sheets.map((item, index)=>{
              
              return (
                <div className='rounded h-24' key={index}>
                <div className='h-1/2 py-4 text-black text-xl py-5'>{item.displayName}</div>
                <button
                    className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>{setSelectedSheet(item.displayName)}}
                  >
                     download 
                  </button>
            </div>
              )
            })
          } */}
          <CSVLink data={projectSummaryData} filename={`Project_Summary_${city}_${fromDate}_${toDate}`}>
              <div className='rounded-lg h-28 bg-white m-4' >
                  <div className='h-1/2  text-2xl'>Project Summary</div>
                  <button
                      className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-lg px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      // onClick={()=>{setSelectedSheet(item.displayName)}}
                    >
                      <div className='flex flex-row '>

                      <span><svg className='fill-current text-white h-8 w-8 pr-2' xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960"><path d="M480-313 287-506l43-43 120 120v-371h60v371l120-120 43 43-193 193ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z"/></svg></span>
                     <span> download</span>  
                      </div>
                    </button>
                </div>
            </CSVLink>

            
         <div className='rounded-lg h-28 bg-white m-4' >
                  <div className='h-1/2 text-2xl'>Property Survey</div>
                  <button
                      className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-lg px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handlePropertySurveyDownload}
                    >
                      <div className="flex flex-row">
                      <svg className='fill-current text-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-462 140-685v465h390v60H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v310h-60v-255L480-462Zm0-60 340-218H140l340 218ZM774-44l-42-42 73-74H590v-60h215l-74-74 43-42 146 146L774-44ZM140-685v499-244 4-314 55Z"/></svg>
                      <span className='pl-2'>send</span>
                      </div>
                    </button>
                </div>
                <div className='rounded-lg h-28 bg-white m-4' >
                  <div className='h-1/2 text-2xl'>Cluster Report</div>
                 
                    <button
                      className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-lg px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleClusterReportDownload}
                    >
                     <div className="flex flex-row">
                      <svg className='fill-current text-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-462 140-685v465h390v60H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v310h-60v-255L480-462Zm0-60 340-218H140l340 218ZM774-44l-42-42 73-74H590v-60h215l-74-74 43-42 146 146L774-44ZM140-685v499-244 4-314 55Z"/></svg>
                      <span className='pl-2'>send</span>
                      </div>
                    </button>
                </div>
         </div>

         {
          openPrompt &&
         <EmaiPrompt openPrompt setOpenPrompt={setOpenPrompt} emails={emails} setEmails={setEmails}/>
         } 
         
         <ToastContainer />
    </div>
  )
}

export default ReportSheet