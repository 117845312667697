import React,{useEffect, useState, Component, Fragment } from "react";
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import useVendor from './vendor-data-fetch/vendor-data';
import { useNavigate } from 'react-router-dom';

// import toast from "react-toastify";
import format from 'date-fns/format';
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import { DropDown } from '../../Ui-Elemnent/DropDown'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WardWiseClustureTable from "./WardwiseClustureTable";

// interface Column {
//   id: 'wardNo' | 'totalClustersAvailable' | 'totalScannedClusters' | 'totalUnscannedClusters' | 'clusterScanningPercent';
//   label: string;
//   minWidth?: number;
//   align?: 'right';
//   format?: (value: number) => string;
// }

// const columns: readonly Column[] = [
//   { id: 'wardNo', label: 'Ward No', minWidth: 40 , format: (value: number) => value.toLocaleString('en-US'),},
//   { id: 'totalClustersAvailable', label: 'Total Clusters available', minWidth: 40, format: (value: number) => value.toLocaleString('en-US'), },
//   {
//     id: 'totalScannedClusters',
//     label: 'Total Scanned Clusters',
//     minWidth: 40,
//     align: 'right',
//     format: (value: number) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'totalUnscannedClusters',
//     label: 'Total unscanned clusters',
//     minWidth: 40,
//     align: 'right',
//     format: (value: number) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'clusterScanningPercent',
//     label: '% of cluster scanning',
//     minWidth: 40,
//     align: 'right',
//     format: (value: number) => value.toLocaleString('en-US'),
//   },
// ];

// interface Data {
//   wardNo: number;
//   totalClustersAvailable: number;
//   totalScannedClusters: number;
//   totalUnscannedClusters: number;
//   clusterScanningPercent: number;
// }

// function createData(
//   wardNo: number,
//   totalClustersAvailable: number,
//   totalScannedClusters: number,
//   totalUnscannedClusters: number,
//   clusterScanningPercent: number
// ): Data {

//   return { wardNo, totalClustersAvailable, totalScannedClusters, totalUnscannedClusters, clusterScanningPercent };
// }

// const rows = [
//   createData(1, 19, 13, 6,12),
//   createData(2, 13, 7, 6,44),
//   createData(3, 31, 6, 25,66),
//   createData(4, 12, 4, 8,77),
//   createData(5, 12, 3, 9,55),
// ];
export default function WardWiseClustureReport({city, range, setRange}:{city:string, range:any, setRange:any}) {

  // Pagiation
  const [totalCount, setTotalCount] = useState<number>(0)
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1)

  useEffect(()=>{
    setSkip((currentPage -1) * limit)
    
  },[currentPage, limit ])

  const clickPrevious = ()=>{
  currentPage !== 1 ?  setCurrentPage(currentPage - 1) :setCurrentPage(1)
  }
  const clickNext = ()=>{
     setCurrentPage(currentPage + 1) 
    }
  // DateRange
  const [dayDiff, setDaydiff] =useState<any>();
  const today = new Date();
  const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [filtered, setfiltered] =useState<any>();
  const navigate = useNavigate();
  // const { vendorsDetails } = useVendor()
  const vendorsDetails :any= [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const [range, setRange] = useState<any[]>([
  //   {
  //     endDate: new Date(),
  //     startDate: today.setDate(today.getDate()-6),
  //     key: 'selection', 
  //   }
  // ]);

  useEffect(()=>{
    // console.log("range in ward", range)

    setToDate(format(range[0].endDate, "yyyy-MM-dd"))
    setFormDate(format(range[0].startDate, "yyyy-MM-dd"))

    setDaydiff((range[0].endDate - range[0].startDate) / 86400000);

  },[range, toDate, fromDate]);

  // let { filtered } = this.state;
    let pageSize = filtered ? filtered.length : 10;
    // console.log(this.state);
    // const { cityName } = this.props;
  
  return (
  
    <div className=''>
            <div className='w-full ml-2 mt-8 h-1/2'>
     
      <div className=''>
      
        <div>
            <WardWiseClustureTable city={city}  skip={skip} limit={limit} setTotalCount={setTotalCount} range={range} setRange={setRange}/>
        </div>
    <div>
     
    </div>
    </div>
    </div>

    <div className='flex justify-start w-1/2 items-end mt-2 mx-8'>
        <button className={`border rounded bg-lightBlue hover:bg-recity hover:text-white p-2 disabled:opacity-25 mr-2 `} onClick={clickPrevious} disabled={currentPage === 1 ? true : false} >Previous</button>
        <button type='button' className={`border rounded bg-lightBlue hover:bg-recity hover:text-white p-2 disabled:opacity-25`} onClick={clickNext} disabled={(currentPage * limit) >  totalCount ? true: false}>Next</button>
        <div className='mx-5'>{`Showing ${skip + 1 > totalCount ? totalCount :  skip + 1 } to ${ currentPage * limit >  totalCount ? totalCount :currentPage * limit} out of ${totalCount} data`}</div>
        <DropDown list={[10,20,50]} selected={`${limit} entries per row`} setSelected={setLimit}/>
    </div>
    </div>
  )
}

// export default Report