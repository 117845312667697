import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useCollectionDetails = ({city, fromDate, toDate, setScannedProperties, setTotalProperties }:{city:string, fromDate:string, toDate:string, setScannedProperties:any, setTotalProperties:any}) => {
  const [collectionData, setCollectionData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/collection?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();

        const dates = Object.keys(result.data.collectionData);

        const propertiesCovered = dates.map((date) => {
                let scannedProperty = result.data.collectionData[date].propertiesCovered;
                let totalProperties = result.data.collectionData[date].totalProperties;
                return scannedProperty/totalProperties *100
              });
              const scannedPropertiesCount = dates.map((date) => {
                return result.data.collectionData[date].propertiesCovered;

              });
              const totalPropertiesCount = dates.map((date) => {
                return result.data.collectionData[date].totalProperties;

              });
              // console.log("totalPropertyCount", totalPropertiesCount);
              
        if (!didCancel) {
          setScannedProperties(scannedPropertiesCount.reduce((a,b)=> a+b));
          setTotalProperties(totalPropertiesCount.reduce((a,b)=> a+b));
          setCollectionData({
            labels: Object.keys(result.data.collectionData),
            datasets: [
              {
                label: "Scanned Collection",
                data: propertiesCovered,
                borderColor: "#ADD8E6",
                backgroundColor: "#23418D",
              },
            ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {  collectionData, loading, error };
};

export default useCollectionDetails ;



