import React,{useState, useEffect, useCallback} from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
import FilterComponent from "../FilterComponent";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import format from 'date-fns/format';
import useWasteWorkerWiseData from "../data-fetch/waste-worker-wise-data";
import CalenderSelctor from "../../Ui-Elemnent/Calender";
const rowHeading = [{heading:"WW Name", key:'name',  isChecked:true}, {heading:"Cluster DDN", key: 'clusterDDN' , isChecked:true}, {heading:"Area Name", key: 'landmark', isChecked:true}, {heading:"Scanning Status", key: 'status',isChecked:true},  ]


export default function WasteWorkerTable({city,  skip, limit, setTotalCount, range}:{city:string, skip:number, limit:number, setTotalCount:any, range:any}) {
    
     ////// Date Range Selector
    //  console.log(range[0]?.startDate)
     const today = new Date()
     
     const [calendar, setCalendar] = useState<any>(today.setDate(today.getDate()-1));
     const [selectdDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));

     useEffect(()=>{
        setCalendar(range[0]?.endDate)
     },[range])
     ///////////////
     const navigate = useNavigate();
     const [searchParams] = useSearchParams();
    // console.log("wardNumber", searchParams.get('wardNo'))
       const wardNumber = searchParams.get('wardNo') || 0;
       const wwid = searchParams.get('wwid') || "-";
    const { wwData, loading} = useWasteWorkerWiseData({city:city,date:format(calendar, "yyyy-MM-dd"),skip:skip, limit:limit, setTotalCount:setTotalCount, wardNumber: wardNumber, wwid:wwid });
    // console.log("wardClustersData",wardClustersData);
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [clusterList, setClusterList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);
    
    useEffect(()=>{
        setSelectedDate(format(calendar, "yyyy-MM-dd"))
    },[calendar])
    
    type Data = typeof wwData;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(wwData)
        
    },[ wwData])

    const headers = [
        {label:"Name", key:'name'},
         {label:"Cluster DDN", key: 'clusterDDN' }, 
         {label:"Area Name", key: 'landmark'}, 
         {label:"Scanning Status", key: 'status'}, 
        //  {label:"Un-scanned Clusters", key: 'totalUnscannedClusters'}, 
        //  {label:"Un Assign-scanned Clusters", key: 'unAssignedScannedClusters'}, 
        //  {label:"% of Clusters scanning", key: 'clusterScanningPercent'}
        
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
   
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: wwData, sortKey, reverse : sortOrder === 'desc'}),[wwData, sortKey, sortOrder])
      ///
      useEffect(()=>{
        setData(sortedData())
    },[sortedData]);

      useEffect(()=>{
        setClusterList(data?.slice(skip, (skip + limit)))
    },[skip, limit, data, sortedData]);
    
    

    const handleChange = ( value:string)=>{
        setSearchText(value)
           
         }   

        useEffect(()=>{
            filterData(searchText)
        },[searchText])

    const filterData = (value:string)=>{
        const lowerCaseValue =  value.toLowerCase().trim();
        // console.log("lowerCaseValue",lowerCaseValue);
        
        if(!lowerCaseValue){
            setData(sortedData())
        }else {
            const filteredData = sortedData().filter(item =>{
                // console.log("item", item)
                return Object.keys(item).some(key =>{
                   
                    return item[key].toString().toLowerCase().includes(lowerCaseValue)
                })
            });
            // console.log("filteredData",filteredData);
            
            setData(filteredData)
            // console.log(data);
            
        }
    } 
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        }   
        

  
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center border border-gray rounded-md mr-4" >
                        <div className="relative">
                        <CSVLink data={data?.length !== 0 && data !== undefined ? data : ''} filename={`Clusture_Report_${city}__${selectdDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download 
                                        
                                    </div>
                                </span>
                            </button>
                            </CSVLink>
                        </div>
                    </div>
                    <div className='flex justify-end  '>
                    {<CalenderSelctor calendar={calendar} setCalendar={setCalendar} />}

                     </div>
                     <button className={`border rounded rounded-lg bg-white hover:bg-recity hover:text-white p-2 disabled:opacity-25 ml-2 `} onClick={()=>{navigate(-1)}}>
                    Back
                    </button>
                </div>
                <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter}  filteredColumn={filteredColumn} setFilteredColumn={setFilteredColumn}/>}
                </div>
                
                <div className="p-1.5 w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-4 py-2 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.isChecked &&  item.heading }
                                    </th>
                                            )
                                        })
                                    }
                                    
                                    
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    clusterList?.map((item, index)=>{
                                        
                                        return(
                                            <tr key={index}>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                                {filteredColumn[0].isChecked && item?.name}
                                            </td>
                                           
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                                {filteredColumn[1].isChecked && item?.clusterDDN}
                                            </td>
                                            <td className=" py-2 text-sm text-gray-800 whitespace-nowrap">
                                            {
                                                filteredColumn[2].isChecked && item?.landmark
                                                // `RC-WBKP-WW-1-000002`
                                            }
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                                    {
                                                    filteredColumn[3].isChecked && item?.status
                                                    }
                                                
                                            </td>

                                            {/* <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked &&( Math.round(item?.assignedClusters ))}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked &&( Math.round(item?.totalScannedClusters))}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked &&( Math.round(item?.totalUnscannedClusters))}
                                           
                                            </td>
                                            <td className="py-2 text-sm text-gray-800 whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked &&( Math.round(item?.unAssignedScannedClusters))}
                                           
                                            </td>
                                            <td className={`py-2 text-sm text-gray-800 whitespace-nowrap`}>
                                               {filteredColumn[5].isChecked && item?.clusterScanningPercent}%
                                            </td> */}
                                            
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
        </div>
    );
}