import React,{useState, useEffect, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import useUserList from "./data-fetch/fetch-allUsersList";
import ScaleLoaderItem from "../Ui-Elemnent/ScaleLoader";
import FilterComponent from "./FilterComponent";
import useSearchedUserList from "./data-fetch/user-search";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../Ui-Elemnent/DateRange'
import format from 'date-fns/format';
// import { IoSwapVerticalOutline } from "react-icons/io5";
const rowHeading = [{heading: "s. no." ,  isChecked:true},  {heading:"Name",  isChecked:true}, {heading:"ward no.", key: 'ownersname' , isChecked:true}, {heading:"Mobile No.", isChecked:true},{heading:"User Role", isChecked:true}, {heading:"Username",key: 'noOfDaysWaste', isChecked: true} , {heading:"View",key: 'noOfDaysWaste', isChecked: true}, {heading:"Status",key: 'noOfDaysWaste', isChecked: true}]


export default function Table({city,  skip, limit, setTotalCount, setCurrentPage}:{city:string, skip:number, limit:number, setTotalCount:any, setCurrentPage:any}) {
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState<string[]>([]);
    const {userList, loading} = useUserList({city:city,skip:skip, limit:limit, setTotalCount:setTotalCount, userRole:selectedRole});
    const {searchFunction, allUserRole} = useSearchedUserList()
    // console.log("defaultersData",defaultersData);
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [allUserList, setAllUserList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);

    // filter option for user role
   
    const [ userRole, setUserRole] = useState<string[]>([]);


        useEffect(()=>{
        const userRoleList = async ()=>{
          const role =await allUserRole();
          let tempArr = role?.map((rol:string)=>{
            return {heading: rol, isChecked:true}
          })
          setUserRole(role);
          setSelectedRole(tempArr);
        };
        userRoleList();
      },[]);

    //   useEffect(()=>{
    //     console.log(selectedRole)
    //   },[selectedRole])
    


    ///////
    type Data = typeof userList;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(userList)   
    },[userList])

    const headers = [
        { label: 'PU DDN', key: 'PUDDN' },
        { label: 'Owner Name', key: 'ownersname' },
        { label: 'Contact No.', key: 'contactNo' },
        { label: 'Ward No.', key: 'wardNo' },
        { label: 'No of Days waste not segregated', key: 'noOfDaysWaste' },
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

     
   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
   
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: userList, sortKey, reverse : sortOrder === 'desc'}),[userList, sortKey, sortOrder])

      ///
      useEffect(()=>{
        setData(sortedData())
        },[userList]);

      useEffect(()=>{
        setAllUserList(data.slice(skip, (skip + limit)))
        },[skip, limit, data, sortedData]);
    
    

        const handleChange = ( value:string)=>{
            setSearchText(value)
            
        }   

        useEffect(()=>{
            if(searchText !== ""){

                let searchFuc:any =  setTimeout(()=>{
                     search(searchText);
                 }, 1500);
                 return () => clearTimeout(searchFuc)
            } else{
                setData(userList)
            }
        },[ searchText])


        const search = async (value:string)=>{
        const lowerCaseValue =  value.toLowerCase().trim();
        console.log("lowerCaseValue",lowerCaseValue);
        
        if(!lowerCaseValue){
            setData(sortedData())
        }else {
           const serchedData =await searchFunction({city, searchString:searchText})
            setData(serchedData)
            console.log(data);
            
        }
    } 
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        }    
        
        // Handle view and update user
        const handleViewUser = (id:string)=>{
            navigate(`/user/view/${id}`)
        }
  
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center border border-gray rounded-md mx-4" >
                        <div className="relative">
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" onClick={e => setOpenFilter(!openFilter)}>
                                <span className="relative inline-flex items-center px-3 py-3  space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-3 h-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Filters
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center border border-gray rounded-md mr-4" >
                        <div className="relative">
                        {/* <CSVLink data={sortedData()} filename={`Defaulters_list_${city}_${fromDate}_${toDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download 
                                        
                                    </div>
                                </span>
                            </button>
                            </CSVLink> */}
                        </div>
                    </div>
                    <div className='flex justify-end  '>
                </div>
                </div>
                <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter} userRole={userRole} selectedRole={selectedRole} setSelectedRole={setSelectedRole}/>}
                </div>
                
                <div className="p-1.5 inline-block min-w-full align-middle">
                    <div className="overflow-visible border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-6 py-3 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.isChecked &&  item.heading }{item.isChecked && item.heading  ===  "No. of days waste not segregated"  ? ' ⇵' : '' }{item.isChecked && item.heading ===   'Ward no.' ? ' ⇵' : ''}{item.isChecked && item.heading ===  "Owner's Name"  ? ' ⇵' : '' }
                                        
                                    </th>
                                            )
                                        })
                                    }
                                    
                                    
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    data.map((item, index)=>{
                                        // console.log("item", item);
                                        
                                        return(
                                            <tr key={index}>

                                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                                                {filteredColumn[0].isChecked && skip + index + 1}
                                            </td>
                                            <td className="px-2 py-4 text-sm text-gray-800 capitalize whitespace-nowrap">
                                                {filteredColumn[1].isChecked && item?.name}
                                            </td>
                                            <td className=" py-4 text-sm text-gray-800 whitespace-nowrap">
                                            {filteredColumn[2].isChecked && item?.wardNumber}
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium capitalize  whitespace-nowrap">
                                               
                                                    {filteredColumn[3].isChecked &&` ${item?.mobile || "N A"}`  }
                                                
                                            </td>
                                            {/* <td className="px-6 py-4 text-sm font-medium  capitalize whitespace-nowrap">
                                               
                                               {filteredColumn[4].isChecked && item?.state}
                                           
                                       </td> */}
                                       <td className={`px-6 py-4 text-sm font-medium userRole  whitespace-nowrap`}>
                                               
                                               {filteredColumn[3].isChecked && (item.userRole) }
                                           
                                       </td>
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[4].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                                    { item.username}
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[5].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                            <button
                                                    type="button"
                                                    className={`hover:bg-recity hover:text-white p-2 disabled:opacity-25`}
                                                    onClick={()=>handleViewUser(item._id)}
                                                    //   disabled={currentPage * limit > totalCount ? true : false}
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-recity hover:text-white">
                                                        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                                        <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                                        </svg>


                                                 </button>
                                            </td>
                                            <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[6].isChecked ? "": 'hidden'} text-green whitespace-nowrap`}>
                                                <button>

                                                    {"Active"}
                                                </button>
                                            </td>
                                            {/* <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[6].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                                    { item.username}
                                            </td> */}
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
        </div>
    );
}