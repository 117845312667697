import { useEffect } from "react";

export default function FilterComponent({
  openFilter,
  setOpenFilter,
  filteredPropertyType,
  setFilteredPropertyType,
}: {
  openFilter: boolean;
  setOpenFilter: any;
  filteredPropertyType: any[];
  setFilteredPropertyType: any;
}) {
  // let tempColm:any[] = [];
  
  // useEffect(()=>{
  //   console.log(filteredPropertyType)
  // },[filteredPropertyType])
 
  const handleClick = (e: any) => {
    const { name, checked } = e.target;
    
    if (name === "All Select") {
      // console.log(name, checked)
      let tempColm = filteredPropertyType.map((column) => {
        return { ...column, isChecked: checked };
      });
      setFilteredPropertyType(tempColm);
    } else {
     let tempColm = filteredPropertyType.map((item) =>
        item.type === name ? { ...item, isChecked: checked } : item
      );
      setFilteredPropertyType(tempColm);
    }
  };
  
  
  const handleDone = ()=>{
    // setFilteredPropertyType(tempColm);
    setOpenFilter(false);
  }
  return (
    <>
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setOpenFilter(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-4xl p-4 mx-auto bg-white rounded-lg shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="mb-2 text-center sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-medium text-gray mb-4">
                    Select Property Types.
                  </h4>

                  <div className=" grid grid-cols-3 gap-2  ">
                    {/* <div className='items-start'>

        <input type="checkbox" value="" name="allSelect" className="w-4  h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleClick}/>
                <label  className="ml-2 uppercase text-sm font-medium text-gray-900 dark:text-gray-300">All Select</label>
          </div> */}
                     {/* <div
                          className="border rounded border-borderColor p-2"
                        >
                          <input
                            type="checkbox"
                            value=""
                            name={"allSelect"}
                            defaultChecked={true}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handleClick}
                          />
                          <label className="ml-2 capitalize	 text-sm font-sans font-light">
                            {"Select All"}
                          </label>
                        </div> */}
            
                    {filteredPropertyType.map((item, index) => {
                      return (
                        <div
                          key={item.type}
                          className="border rounded border-borderColor p-2"
                        >
                          <input
                            type="checkbox"
                            value=""
                            // isChecked={topping.checked}
                            name={item?.type}
                            defaultChecked={item?.isChecked}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handleClick}
                            checked={item?.isChecked}
                          />
                          <label className="ml-2 capitalize	 text-sm font-sans font-light">
                            {item?.type}
                          </label>
                        </div>
                        
                      );
                    })}
                  </div>
                  <div className="items-center gap-3 mt-3 sm:flex">
                  <button
                    className="w-full bg-lightBlue text-Black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setOpenFilter(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-full bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleDone()}
                  >
                    Done
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

