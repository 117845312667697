import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useBagsRecievedDetails = ({city, fromDate, toDate, setNoOfBagsRecieved }:{city:string, fromDate:string, toDate:string, setNoOfBagsRecieved:any}) => {

  
  const [bagsRecievedData, setBagsRecievedData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ {
        label: "No. Of Bags Recieved",
        data: [0],
        borderColor: "#ADD8E6",
        backgroundColor: "#23418D",
      },],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/bagsData?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result.data.materialTransferData);
        const dates = Object.keys(result.data.materialTransferData);
        // const types = Object.keys(result.data.materialTransferData);
          if(dates.length){
            
            const toatlBagsRecieved = dates.map((date) => {
                    return result.data.materialTransferData[date].total_bags_count;
                  });
            if (!didCancel) {
              setNoOfBagsRecieved(toatlBagsRecieved.reduce((a,b)=> a+b))
              setBagsRecievedData({
                    labels: dates,
          datasets: [
            {
              label: "No. Of Bags Recieved",
              data: toatlBagsRecieved,
              borderColor: "#ADD8E6",
              backgroundColor: "#23418D",
            },
          ],
              });
          } 
        } else {
          if (!didCancel) {
            setNoOfBagsRecieved(0)
            setBagsRecievedData({
                  labels: dates,
        datasets: [
          {
            label: "No. Of Bags Recieved",
            data: [0],
            borderColor: "#ADD8E6",
            backgroundColor: "#23418D",
          },
        ],
            });
        } 
        }
        didCancel =true
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, toDate, fromDate]);

  return {  bagsRecievedData, loading, error };
};

export default useBagsRecievedDetails ;