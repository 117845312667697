import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useUserList = ({city, skip, limit, setTotalCount, userRole}:{city:string, skip:number, limit:number, setTotalCount:any, userRole:any[]}) => {

  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // useCallback(()=>{

  // },[])

  

  useEffect(() => {
    const rolesQueryString = userRole
  .filter(role => role.isChecked)
  .map(role => role.heading);
    
    console.log(rolesQueryString);

    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/user/list?city=${city}&limit=${limit}&skip=${skip}&roles=${rolesQueryString}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
        
        let usersList = result?.data?.usersList
        setTotalCount(result?.data?.usersCount)
        setUserList(usersList);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city,limit, skip, userRole]);

  return { userList, loading, error };
};

export default useUserList ;
