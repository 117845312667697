import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useAllWWList = ({city, skip, limit, date, setTotalCount}:{city:string, skip:number, limit:number,  date:string, setTotalCount:any}) => {
  let token = localStorage.getItem("auth-token") || "";
  const [userList, setUserList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({})
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/attendance/ww-attendance?city=${city}&limit=${limit}&skip=${skip}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
              // "x-user-token": token
              "x-user-token": `${JSON.parse(token)}`
            },
          }
        );

        const result = await response.json();
        console.log("result", result?.data);
        
      
        setUserList(result?.data?.wwAttendanceList);
        setTotalCount(result?.data?.wwAttendanceCount)
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, limit, skip, date, token]);


  return {  userList, loading, error };
};

export default useAllWWList ;
