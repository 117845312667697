import React, { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill, BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const Menus = [
  {
    name: "Insights",
    icon: "/dashboard.png",
    link: "/",
  },
  {
    name: "User Management", icon: '/user_mang.png', subMenu:[
        {title: "All User", link:"/user/all"},
    ]
},
  {
    name: "Defaulter List",
    icon: "/report.png",
    link: "/defaulters-list",
  },
  {
    name: "Attendance",
    icon: "/user.png",
    link: "/attendance",
  },
  {
    name: "Fees Collection",
    icon: "/price.png",
    link: "/ucf",
  },
    {
      name: "Fleet Management", icon: '/user_mang.png', subMenu:[
          {title: "Fleet Operation", link:"/fleet/location"},
          {title: "Vehicle Management", link:"/fleet/vehicle"},
      ]
  },
  // {
  //   name: "Fleet Management",
  //   icon: "/report.png",
  //   link: "/fleet/location",
  // },
  {
    name: "GRS",
    icon: "/report.png",
    link: "https://grs.recity.in/#/admin/stats",
  },
  // {
  //   name: "FMS",
  //   icon: "/price.png",
  //   link: "/report-sheets",
  // },
  // {
  //   name: "FMS",
  //   icon: "/price.png",
  //   link: "/fms",
  // },
//   {
//     name: "Reports Sheets",
//     icon: "/report.png",
//     link: "/report-sheets",
//   },
//   {
//       name: "User Management", icon: '/user_mang.png', subMenu:[
//           {title: "All User", link:"/"},
//           {title: "Add User", link:"/"},
//       ]
//   },
{
        name: "FACILITIES", icon: '/user_mang.png', subMenu:[
            {title: "Operation Insights", link:"/fms"},
            {title: "FMS", link:"https://auth.cetraces.in:8443/auth/realms/Recity/protocol/openid-connect/auth?client_id=fms-frontend-prod&redirect_uri=https%3A%2F%2Fwww.cetraces.in%2F&state=45c297d6-4507-48a4-aa99-187317d08a3e&response_mode=fragment&response_type=code&scope=openid&nonce=74bc9277-dd46-4190-93d7-4be5ef211ef2"},
        ]
    },
  {
      name: "Property Management", icon: '/property.png', subMenu:[
          {title: "All Properties", link:"property/all"},
          {title: "TSP", link:"property/tsp"},
      ]
  },
  // {
  //     name: "City Management", icon: '/city.png', subMenu:[
  //         {title: "All Cities", link:"/"},
  //         {title: "Add City", link:"/"},
  //     ]
  // },
  // {
  //     name: "Price Management", icon: '/price.png', subMenu:[
  //         {title: "Edit Price", link:"/"},
  //         {title: "History", link:"/"},
  //     ]
  // },
  {
      name: "Cluster Management", icon: '/cluster.png', subMenu:[
          {title: "All Clusters", link:"/cluster/all"},
          // {title: "Add Cluster", link:"/"},
      ]
  },
  {
    name: "Reports Sheets",
    icon: "/report.png",
    subMenu: [
      { title: "Cluster", link: "/reports/city" },
      { title: "Summary Reports", link: "/report-sheets" },
      // {title: "Attendance", link:"/"},
      // {title: "Bin Clean Up", link:"/"},
      // {title: "Material Transfer", link:"/"},
      // {title: "Material Recovery", link:"/"},
      // {title: "Summery sheet", link:"/"},
    ],
  },
  // {
  //     name: "Dashboard", icon: '/dashboard.png'
  // },
  // {
  //   name: "IEC Activities",
  //   icon: "/report.png",
  //   link: "#",
  // },
  {
    name: "Help & Support",
    icon: "/report.png",
    link: "https://recity.freshdesk.com/support/tickets/new",
  },
];

const SideMenuBar = ({
  selectedTab,
  setSelectedTab,
  selectedSubTab,
  setSelectedSubTab,
}: {
  selectedTab: string;
  setSelectedTab: any;
  selectedSubTab: string;
  setSelectedSubTab: any;
}) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  // const [open, setOpen] = useState(true);

  const [selectedSubMenu, setSelectedSubMenu] = useState(-1);
  const [subMenuIndex, setSubMenuIndex] = useState(-1);
  useEffect(() => {
    const checkTabArr = pathname.split("/")
    const checkTab = checkTabArr[1];
    console.log(checkTab)
    checkTab === "fms"   
      ? setSelectedTab("MRF INSIGHTS")
    :checkTab === "cluster"   
      ? setSelectedTab("Cluster Management")
    :checkTab === "fleet"   
      ? setSelectedTab("Fleet Management")
    :checkTab === "property"   
      ? setSelectedTab("Property Management")
    : checkTab === "reports" || checkTab === "report-sheets"  
      ? setSelectedTab("Reports Sheets")
      : checkTab === "defaulters-list"
      ? setSelectedTab("Defaulter List")
      : checkTab === "ucf"
      ? setSelectedTab("Fees Collection")
      : checkTab === "attendance"
      ? setSelectedTab("Attendance")
      : setSelectedTab("Insights");
  }, [pathname]);
  const handlesubMenu = (index_p: number) => {
    let curruntIndex = index_p === subMenuIndex ? -1 : index_p;
    setSubMenuIndex(curruntIndex);
    setSelectedSubMenu(-1);
  };

  const handleSubTab = (subTab:any) => {
    if(subTab.title==="FMS"){
      window.open(subTab.link, '_blank', 'noreferrer');
    }
    setSelectedSubTab(subTab?.title)
    navigate(subTab?.link)
  };

  const handleTabs = (tab: any) => {
    if(tab.name==="GRS"){
      window.open(tab.link, '_blank', 'noreferrer');
    }
    // if(tab.name==="IEC Activities"){
    //   window.location.replace(tab.link);
    // }
    if(tab.name==="Help & Support"){
      window.open(tab.link, '_blank', 'noreferrer');
    }
    
    setSelectedTab(tab.name);
    if (!tab.subMenu) {
      navigate(tab?.link);
    }else{
      navigate(tab?.subMenu?.[0]?.link)
    }
    // console.log("tab", tab);
  };
  return (
    <div
      className={`bg-recity  mt-12 p-6 pt-8 relative  duration-300 w-1/6
      overflow-y-auto min-h-screen `}
    >
      {/* <BsFillArrowLeftCircleFill
        className={`text-3xl text-lightBlue absolute -right-3 cursor-pointer duration-500 ${
          !open && "rotate-180"
        }`}
        onClick={() => setOpen(!open)}
      /> */}
      <ul>
        {Menus.map((menu, index) => {
          return (
            <>
              <li
                key={index}
                className={`text-gray-500 text-sm flex items-center hover:bg-sky  gap-x-4 cursor-pointer p-2 rounded-md mt-2 ${
                  menu.name === selectedTab
                    ? "bg-lightBlue text-recity"
                    : "text-white"
                }`}
                onClick={() =>{ 
                  handleTabs(menu)
                  handlesubMenu(index)
                }}
              >
                <span className="text-2xl  block float-left">
                  <img className="h-4 w-4" src={`${menu.icon}`} alt="" />{" "}
                </span>
                <span
                  className={`text-base text-left font-medium flex-1 duration-300`}
                >
                  {menu.name}{" "}
                </span>
                {menu.subMenu && (
                  <BsChevronDown
                    className={`${subMenuIndex === index && "rotate-180"} `}
                    onClick={() => handlesubMenu(index)}
                  />
                )}
              </li>
              {menu.subMenu && subMenuIndex === index &&  (
                <ul>
                  {menu?.subMenu?.map((item, index) => {
                    return (
                      <>
                        <li
                          key={index}
                          className={`text-gray-500 text-sm flex  hover:bg-sky  gap-x-4 cursor-pointer p-2 pl-8 rounded-md mt-2 ${
                            selectedSubTab === item.title
                              ? "bg-lightBlue text-recity"
                              : "text-white"
                          }`}
                          onClick={() => handleSubTab(item)}
                        >
                          <span className="capitalize">{item.title}</span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              )}
            </>
          );
        })}
      </ul>
      
    </div>
  );
};

export default SideMenuBar;
