import { useEffect, useState } from "react";
import ImageViwer from "../ImageViwer";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useClusterData from "../data-fetch/cluster-data-fetch";
import usePropertyDetails from "../data-fetch/property-details";
import useUpdateCluster from "../data-fetch/update-cluster";
import AiFillEye from "react-icons/ai"
export default function ViewCluster() {
    const navigate = useNavigate();
    const params:any = useParams();
    const id:string = params.id || ""
    // console.log("params", id);
    const {getPropertyDetails} = usePropertyDetails();
    const { updateCluster, updateImage} = useUpdateCluster();
    const clusterDetails = useClusterData({id});
    let cluster = clusterDetails.clusterDetails;
    // console.log("ClusterDetails", cluster)
    const [openImage, setOpenImage] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>("");
    const [image, setImage] = useState<any>({})
    const [propertyInfo, setPropertyInfo] = useState<any[]>([])
    const handleViewClusterButton = (e:any, imgUrl:string) =>{
      e?.preventDefault();
      if(imgUrl){

        setImgUrl(imgUrl);
        setOpenImage(true);
      }else{
        toast("Image is not available.", {type:"error", theme:"colored"})
      }

    }
    const handleBack = (e:any)=>{
      e.preventDefault();
      navigate(-1)
    }
    useEffect(()=>{
      
     ( async () =>{
      
      const imageUpload =await updateImage(image);
      // console.log("image upload", imageUpload)
       setImgUrl(imageUpload?.data?.Location);
       if(imageUpload.success){

         toast("Image Uploaded Successfully, to link with this cluster, please click on update cluster button.", {type:"success", theme:"colored"})
       }
      })()
    }, [image])
    useEffect(() => {
      const fetchData = async () => {
        const tempArr = [];
        if (cluster && cluster.properties) {
          // Map the array of properties to an array of promises
          const promises = cluster.properties.map(async (element:any) => {
            return await getPropertyDetails(element);
          });
    
          // Wait for all promises to resolve
          const propertyData = await Promise.all(promises);
          
          tempArr.push(...propertyData);
    
          setPropertyInfo(tempArr);
        }
      };
    
      fetchData();
    }, [cluster]);
    const handleUpdateButton = async(e:any) =>{
      e.preventDefault();
      const body:any = {
        location: {
          "type": "Point",
          "coordinates": [
            e.target.longitude.value, e.target.lattitude.value
          ]
      },
        landmark: e.target.landmark.value,
        area: e.target.area.value,
        clusterImage: imgUrl,
      }
     const update = await updateCluster(cluster?._id, JSON.stringify(body));
     console.log(update)
     toast(update.message, {type:"success", theme:"colored"})
     
    }
    return (
        <>
          <div className="m-20">
            <div className="p-y-20  rounded-md bg-slate flex justify-between">
              <div className="flex flex-row">
                <div className="m-2 uppercase text-recity uppercase">Cluster ID :</div>
                <div className="m-2 uppercase text-recity">{cluster.clusterDDN}</div>
              </div>
             
              <div className="flex flex-row">
                <div className="m-2 ">Created by :</div>
                <div className="m-2">{cluster.createdby}</div>
              </div>
            </div>
            
          <form className="w-full mt-8" onSubmit={handleUpdateButton}>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  State
                  </label>
                  <input className="appearance-none block w-full bg-recity text-white  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" defaultValue={cluster?.state}/>
                </div>  
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    City
                  </label>
                  <input className="appearance-none block w-full bg-recity text-white  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" defaultValue={cluster?.city}/>
                </div>   
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Cluster Latitude
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="text" placeholder="" name="lattitude" defaultValue={cluster?.location?.coordinates[1]}/>
                </div>  
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    Cluster Longitude
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="text" placeholder="" name="longitude" defaultValue={cluster?.location?.coordinates[0]}/>
                </div>
                
              </div>



              <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    Cluster Landmark
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="text" name="landmark" placeholder="Cluster Landmark ...." defaultValue={cluster?.landmark}/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    Area
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="area"  id="grid-last-name" type="text"  placeholder="Cluster Landmark ...."  defaultValue={cluster?.area || "N A"} />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Cluster Image
                  </label>
                  {/* <input className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={property?.propertyType}/> */}
                  <button className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight" onClick={(e)=>handleViewClusterButton(e, cluster?.clusterImage && cluster?.clusterImage)}>
                  View Cluster Image
                  </button>
              </div>    
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Upload Cluster Image
                  </label>
                  {/* <input className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={property?.propertyType}/> */}
                  <input type="file" accept={'image/*'} name="clusterImg" className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight" onChange={(e:any)=>{setImage(e.target.files[0])}} />
                  
                  
              </div>  
              </div>

  
  {/* <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        Password
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-password" type="password" placeholder="******************" />
      <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
    </div>
  </div> */}
            <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                Ward Number
                </label>
                <input className="appearance-none block w-full bg-recity uppercase text-white border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled  id="grid-last-name" type="text" placeholder="Cluster Landmark ...." value={cluster.wardNumber || "N A"}/>
              </div> 
              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                  Area
                </label>
                <div className="relative">
                  <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-state" value={cluster?.area || "NA"}>
                    <option>{cluster?.area || "NA"}</option>
                    
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>    */}
              </div>
              <div className="">
           
<div className="relative overflow-x-auto shadow-md sm:rounded-lg border">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-400 dark:text-gray-700 border-b">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Property DDN
                </th>
                <th scope="col" className="px-6 py-3">
                    Property Type
                </th>
                <th scope="col" className="px-6 py-3">
                    Property Owner
                </th>
               
            </tr>
        </thead>
        <tbody>
          
           {
             propertyInfo.map((item:any, index:number)=>{
            // console.log(item)
            return(
              
              <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {item?.DDNNumber || "NA"}
                </th>
                <td className="px-6 py-4">
                   {item?.survey[5]?.answer[0]?.answerlabel}
                </td>
                <td className="px-6 py-4 ">
                    {item?.propertyOwnerName || "N A"}
                </td>
                
            </tr>
            )
           })
           
           }

        </tbody>
    </table>
</div>

            </div>
            <div className="flex justify-center">
                    <button className="bg-green p-4 m-4 text-white rounded uppercase" type="submit" >Update Cluster</button>
                    <button className="bg-recity p-4 m-4 text-white rounded uppercase" onClick={(e)=>{handleBack(e)}}>Back</button>
            </div>
           
          </form>
</div>
{openImage && <ImageViwer setOpenImage={setOpenImage} imageUrl={imgUrl}/>}          
          <ToastContainer />
        </> 
    );
  }
  