import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const usePropertySurveyData = () => {

//   const [propertySurveyData, setPropertySurveyData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // useCallback(()=>{

  // },[])
  

//   useEffect(() => {
//     let didCancel = false;
//     setError(null);



//     return () => {
//       didCancel = true;
//     };
//   }, [city, email]);
const propertySurvey =  (async ({city, email}:{city:string, email:string[]}) => {
    try {
      setLoading(true);
     
      const response = await fetch(
        `${BASEURL}/v1/reports/property-survey?city=${city}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      // console.log("result", result);
      // setPropertySurveyData(result.data.collectionDetails)
        
      
     
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  })

  return { propertySurvey,loading, error };
};

export default usePropertySurveyData ;
