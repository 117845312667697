import format from "date-fns/format";
export default function RevisitScheduled({
  showReschedule,
  setShowReschedule,
  data
}: {
  showReschedule: boolean;
  setShowReschedule: any;
  data:any;
  
}) {
//  console.log("data,", data)
  return (
    <>
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowReschedule(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="mb-2 text-center sm:ml-4 sm:text-left">
                  

                  <div className=" grid grid-cols-2 gap-3  ">
                    <div>Property DDN</div>
                    <div className="">{data?.ddn}</div>
                    <div>Owner's Name</div>
                    <div className="">{data?.ownersname}</div>
                   <div> Reschedule Date and Time :</div>
                   <div> {format(Date.parse(data?.reschedule), "dd/MM/yyyy hh:mm:ss" )} </div>
                   <div>Reported By</div>
                    <div className="">{data?.collected_by || "NA"}</div>
                  </div>
                  <div className="items-center  mt-3 sm:flex">
                  {/* <button
                    className="w-full bg-lightBlue text-Black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowReschedule(false)}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="w-full bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowReschedule(false)}
                  >
                    Done
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
