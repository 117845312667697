import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useReportListData = ({city,  skip, limit,toDate,fromDate}:{city:string, skip:number, limit:number,toDate:string,fromDate:string,}) => {
  let token = localStorage.getItem("auth-token") || "";
  const [reporData, setReportData] = useState<any[]>([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const [error, setError] = useState<any>();
    

  useEffect(() => {
    setError(null);
    (async () => {
      try {
        setLoadingReport(true);
        // http://localhost:8001/v1/attendance/report?city=mathura&toDate=2024-01-05&fromDate=2023-12-25
        const response = await fetch(
          `${BASEURL}/v1/attendance/report?city=${city}&to=${toDate}&from=${fromDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-user-token": `${JSON.parse(token)}`
            },
          }
        );

        const result = await response.json();
        console.log("result", result.data.reportList);
        // let ReportData = result?.data?.reportList;
        // let totalClusterCount = result?.data?.totalCluster;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
        setReportData(result?.data?.reportList);
      } catch (err) {
        setError(err);
      } finally {
        setLoadingReport(false);
      }
    })();

  }, [city, toDate,fromDate,skip, limit ]);




  return {  reporData, loadingReport, error };
};

export default useReportListData ;
