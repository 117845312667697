import { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import useClusterData from "../data-fetch/cluster-data-fetch";
// import usePropertyDetails from "../data-fetch/property-details";
// import useUpdateCluster from "../data-fetch/update-cluster";
import useUpdateVehicleData from "../dataFetch/addVehicle";
import FilterComponent from "./FilterComponent";
const availableShifts = ["5 AM - 12PM", "8 AM - 2 PM","2 PM - 8PM"]
export default function ViewVehicle({city}:{city:string}) {
    const navigate = useNavigate();
    const params:any = useParams();
    const id:string = params.id || ""; 
    const {updateVehicle, getVehicleDetails, getClusterList} = useUpdateVehicleData();
    

    // console.log("params", id);
  
    const [vehicleData, setVehicleData] = useState<any>({});
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [selectedCluster, setSelectedCluster] = useState<any[]>([])
 

    useEffect(()=>{
      getVehicleData();
    },[id])
   
    const getVehicleData = async() =>{
      const vehicleDetails = await getVehicleDetails({id});
      console.log(vehicleDetails)
      setVehicleData(vehicleDetails)
      
    }

    const handleBack = (e:any)=>{
      e.preventDefault();
      navigate(-1)
    }

    useEffect(()=>{
      // console.log("asdfghjk", vehicleData.clustermapped)
      // vehicleData.clustermapped ? setSelectedCluster(vehicleData?.clustermapped) : allClusters();
      // allClusters();
      setSelectedCluster(vehicleData?.clustermapped)
    }, [vehicleData]);

    // useEffect(()=>{
    //   console.log(selectedCluster)
    // }, [selectedCluster])

    const allClusters = async()=>{
      const allClusterList = await getClusterList({city});
      const allCluster = allClusterList.map((item:any)=>{
        return {clusterDDN :item.clusterDDN, isChecked : false }
      })
      setSelectedCluster(allCluster)
    }
  
   
    const handleUpdateButton = async(e:any) =>{
      e.preventDefault();
      
      const body:any = {
        vehicleNo: e.target.vehicleNo.value,
        fuelCapacity: e.target.fuelCapacity.value,
        mileage: e.target.mileage.value,
        wardNumber: e.target.ward.value,
        shift: e.target.shift.value,
        city: e.target.city.value,
        driverId: e.target.driverId.value,
        driverHelperID: e.target.driverHelperId.value,
        clustermapped: selectedCluster
      }
     const registerVehicle = await updateVehicle(id, JSON.stringify(body));
     console.log(registerVehicle);
     toast(registerVehicle.message, {type:"success", theme:"colored"})
     navigate(-1)
    };

    const handleFilter = (e:any)=>{
      e.preventDefault();
      setOpenFilter(!openFilter)
    }
    return (
        <>
          <div className="m-20">
            {/* <div className="p-y-20  rounded-md bg-slate flex justify-between">
              <div className="flex flex-row">
                <div className="m-2 uppercase text-recity uppercase">Cluster ID :</div>
                <div className="m-2 uppercase text-recity">{}</div>
              </div>
             
              <div className="flex flex-row">
                <div className="m-2 ">Created by :</div>
                <div className="m-2">{}</div>
              </div>
            </div> */}
            
          <form className="w-full mt-8" onSubmit={handleUpdateButton}>
            <div className="flex flex-wrap -mx-3 mb-6">
                  
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    City
                  </label>
                  <input className="appearance-none block w-full bg-recity text-white  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" name="city" defaultValue={city}/>
                </div>   
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Vehicle Number
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue text-gray-700 uppercase  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue"  id="grid-first-name" type="text" placeholder="Vehicle Number" name="vehicleNo" defaultValue={vehicleData?.vehicleNo}/>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Fuel Capacity
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="number" placeholder="Fuel Capacity" name="fuelCapacity" defaultValue={vehicleData?.fuelCapacity}/>
                </div>  
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                  Mileage
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="number" placeholder="mileage" name="mileage" defaultValue={vehicleData?.mileage}/>
                </div>
                
              </div>



              <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    Ward 
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="text" name="ward" placeholder="Ward ...." defaultValue={vehicleData?.ward}/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
                    Shift
                  </label>
                  <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="shift" id="grid-state" defaultValue={vehicleData?.shift}>
                    {availableShifts.map((item)=>{
                      return(

                        <option>{item}</option>
                      )
                    })}
                    
                  </select>           
                  
                       </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Driver ID
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="driverId"  id="grid-last-name" type="text"  placeholder="driver ID"   defaultValue={vehicleData?.driverId} />
                  
              </div>    
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Driver Helper ID
                  </label>
                  <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="driverHelperId"  id="grid-last-name" type="text"  placeholder="driver Helper ID"   defaultValue={vehicleData?.driverHelperID} />
                  
              </div>    
            
              </div>

  
  {/* <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        Password
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-password" type="password" placeholder="******************" />
      <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
    </div>
  </div> */}
            <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                Cluster mapped
                </label>
                <div className=" grid grid-cols-3 gap-2">
                  {selectedCluster?.map((cluster)=>{
                    return(
                      cluster.isChecked && 
                      <div className="bg-lightBlue">

                        <p>{cluster.clusterDDN}</p>
                      </div>
                    )
                  })}
                </div>

              </div> 
              <div className="w-full md:w-1/2 px-3">
                
                  <button className="appearance-none block w-full bg-recity uppercase text-white border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"   onClick={(e)=> {handleFilter(e)}}>
                    manage Clusters
                  </button>
                </div>
              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                  Area
                </label>
                <div className="relative">
                  <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-state" value={cluster?.area || "NA"}>
                    <option>{cluster?.area || "NA"}</option>
                    
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>    */}
              </div>
    
            <div className="flex justify-center">
                    <button className="bg-green p-4 m-4 text-white rounded uppercase" type="submit" >Update vehicle</button>
                    <button className="bg-recity p-4 m-4 text-white rounded uppercase" onClick={(e)=>{handleBack(e)}}>Back</button>
            </div>
           
          </form>
  </div>
  {
    openFilter &&
            <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter} selectedClusturs={selectedCluster} setSelectedClusters={setSelectedCluster}  />
  }
          <ToastContainer />
        </> 
    );
  }
  