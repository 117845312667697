
import "react-datepicker/dist/react-datepicker.css";
import BinCleanupData from "./BinCleanUp/BinCleanup";
import CityWiseData from './CityWiseData/CityWiseData';
import MaterialTransferData from "./MaterialTransfer/MaterialTransfer";

import WardWiseData from './WardWiseData/WardWiseData';
import SimpleMap from "../../../Fleet-managment/Map";
import Map from "../../../Fleet-managment/GoogleMap";
const Insights = ({city}:{city:string}) => {
  return (
    <div className='max-w-screen-2xl'>
      <div className="m-2 w-1/4">
      <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
        <div className=" text-xl">IEC Count</div>
        <div className='text-3xl font-500'> {city === "Mathura" ? 120 : 0}</div>
      </div>
      </div>
        <div className='mt-6'>
        <CityWiseData city={city}/>
        </div>
        <WardWiseData city={city}/>
        <BinCleanupData city={city}/>
        <MaterialTransferData city={city}/>
        {/* <SimpleMap /> */}
        <Map city={city}/>
    </div>
  )
}

export default Insights