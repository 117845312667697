import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useSearchedPropertyList = () => {


   const searchFunction =  async ({city, searchString}:{city:string, searchString:string}) => {
      try {
      
        const response = await fetch(
          `${BASEURL}/v1/properties/search?city=${city}&searchString=${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data?.propertyList);
        let propertyData = result?.data?.propertyList;

        // setSearchedPropertyList(propertyData);
       
        return propertyData;
      } catch (err) {
       
      } 
    };

  return { searchFunction };
};

export default useSearchedPropertyList ;
