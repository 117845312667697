import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useClusterData = ({id}:{id:string}) => {
  let token = localStorage.getItem("auth-token") || "";
    console.log("id", id)
  const [clusterDetails, setClusterDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({})
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/clusters/cluster/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              // "x-user-token": token
              "x-user-token": `${JSON.parse(token)}`
            },
          }
        );

        const result = await response.json();
        console.log("result", result?.data?.clusterDetails);
        // let propertyData = result?.data?.tSPList?.propertiList;
        // let totalproperties = result?.data?.tSPList?.totalCount;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
      
        setClusterDetails(result?.data?.clusterDetails);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [id]);


  return {  clusterDetails, loading, error };
};

export default useClusterData ;
