import React from 'react'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement,} from 'chart.js';
 
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,BarElement,)

export const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: 'Material Transfer',
      },
    },
  };
const HorizontalBarChart = ({chartData} : {chartData:any} ) => {
    
  return (
    <div className='h-96 w-full'>
        <Bar data={chartData}  options={{ ...options, maintainAspectRatio: false}}/>
    </div>
  )
}

export default HorizontalBarChart