import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useSagrigationDetails = ({city, calendar, setSegrigatedCollectionInWard, setScannedPropertiesInWard }:{city:string, calendar:string, setSegrigatedCollectionInWard:any, setScannedPropertiesInWard:any}) => {
  const [sagrigationData, setSagrigationData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ {
        label: "Segregated Collection",
        data: 0,
        borderColor: "#ADD8E6",
        backgroundColor: "#23418D",
        maxBarThickness: 50
      },
      {
        label: "Scanned Collection",
        data: 0,
       borderColor: "#ADD8E6",
       backgroundColor: "#ADD8E6",
       maxBarThickness: 50
     }, ],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/segregation/ward?city=${city}&to=${calendar}&from=${calendar}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log(result.data);
        
        const wards = Object.keys(result.data.collectionDataByWard);
        //     //   console.log(wards);
              const propertiesCovered = wards.map((ward) => {
                return Number(result.data.collectionDataByWard[ward].propertiesCovered);
              });
              const totalSagregation = wards.map((ward) => {
                return Number(result.data.collectionDataByWard[ward].totalSegregation);
              });
              // console.log("propertiesCovered in ward wise sagtrigation", propertiesCovered)
        if (!didCancel) {
          setSegrigatedCollectionInWard(totalSagregation.reduce((a,b)=> a+b));
          setScannedPropertiesInWard(propertiesCovered.reduce((a,b)=> a+b))
          setSagrigationData({
            labels: wards,
            datasets: [
              {
                label: "Segregated Collection",
                data: totalSagregation,
                borderColor: "#ADD8E6",
                backgroundColor: "#23418D",
                maxBarThickness: 50
                          },
              {
                label: "Scanned Collection",
                data: propertiesCovered,
               borderColor: "#ADD8E6",
               backgroundColor: "#ADD8E6",
               maxBarThickness: 50
             },
            
            ],
          });
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, calendar ]);

  return { sagrigationData, loading, error };
};

export default useSagrigationDetails ;