import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useClusterData = ({city, date,setTotalCount, }:{city:string, date:string, setTotalCount:any}) => {

  const [clustersData, setClustersData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  
  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/reports/cluster-report?city=${city}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
       
       const clustersInfo =  result?.data?.clusterReportSheet.map((item:any)=>{
          return {
            clusterDDN:item.clusterDDN,
            wardNumber:item.wardNumber,
            scanStatus:item.scanned_cluster.length > 0 ? "Yes": "No",
            areaName:item?.scanned_cluster?.[0]?.landmark ,
            assignWW:item?.assignedUser?.[0]?.username,
            name: item?.assignedUser?.[0]?.name,
            scannedWW:item?.scanned_cluster?.[0]?.Collected_by

          }
        })
        console.log("clustersInfo", clustersInfo)
        setClustersData(clustersInfo);
        setTotalCount(result?.data?.totalCluster)
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, date]);

  return {  clustersData, loading, error };
};

export default useClusterData ;
