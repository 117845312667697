import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, Polyline, DistanceMatrixService } from '@react-google-maps/api';
import useGetCordinateList from './dataFetch/cordinateFetch';
import useVehicleDetails from './dataFetch/vehicleDataFetch';
import useVehiclesLastCordinates from './dataFetch/vehicleLastcordinates';
import useScannedClusterDetails from './dataFetch/scannedClusters';
import { faTruckPickup } from "@fortawesome/free-solid-svg-icons";

import format from "date-fns/format";
import { DropDown } from '../Ui-Elemnent/DropDown';
import CalenderSelctor from '../Ui-Elemnent/Calender';
import VehicalSummary from './VehicalSummary';
const containerStyle = {
  width: '100%',
  height: '500px'
};


function GoogleMapComponent({ city }: { city: string }) {
  const { vehicleList, clusterList } = useVehicleDetails({ city: city, skip: 0, limit: 1000 });

  const [center, setCenter] = useState<any>({ lat: 18.57155215, lng: 73.7840491 });
  //date 
  const today = new Date();

  const [calendar, setCalendar] = useState<any>(
    today.setDate(today.getDate())
  );
  const [selectdDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  // Direction service
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [wwid, setWwid] = useState<string>("");
  const [vehicleListArr, setVehicleListArr] = useState<string[]>([]);
  const [vehicle, setvehicle] = useState<any>("select Vehicle");
  const [vehicleMappedCluster, setVehicleMappedCluster] = useState<any[]>([]);
  const [scannedClusters, setScannedClusters] = useState<any[]>([]);
  const [directionCordinate, setDirectionCordinate] = useState<any[]>([]);
  const [showCluserName, setShowClusterNmae] = useState<string>("");
  const [distanceTravelled, setDistanceTravelled] = useState("");
  const [zoom, setZoom] = useState<number>(13);
  const [totalMappedCluster, setTotalMappedCluster] = useState<number>()


  //
  // Data related to Live Location

  const { getLastCordinatesOfVehicle } = useVehiclesLastCordinates();
  const { scannedClusterList } = useScannedClusterDetails({ wwid: wwid, date: selectdDate })
  ////////////////////////////////



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || ""
  });
  const { cordinateData } = useGetCordinateList({ city: city, date: selectdDate });

  const [currentVehicleCoordinate, setCurrentVehicleCordinate] = useState<any>([]);
  const [selectVehicle, setSelectedVehicle] = useState<any>({})
  const [polylineCordinates, setPolyLineCordinates] = useState<any[]>([]);



  useEffect(() => {

    if (clusterList.length) {

      setCenter({ lat: clusterList[0]?.location?.coordinates[1], lng: clusterList[0]?.location?.coordinates[0] })
    }
  }, [clusterList])




  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(zoom)

    setMap(map)
  }, [center])

  useEffect(() => {
    setSelectedDate(format(calendar, "dd/MM/yyyy"));
  }, [calendar]);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  // Direction service
  useEffect(() => {
    setvehicle(vehicleList[0]?.vehicleNo);
    let newVehicleListArr = vehicleList.map((item, index) => {
      return item.vehicleNo
    });
    setVehicleListArr(newVehicleListArr)
  }, [vehicleList]);

  useEffect(() => {
    // console.log(clusterList);
    // console.log("vehicle", vehicle)
    for (let item of vehicleList) {
      if (item.vehicleNo === vehicle && item?.driverId) {
        // console.log(item) 
        setSelectedVehicle(item);
        setWwid(item?.driverId)
        // getLastCordinates(item?.driverId)
        const intervalId = setInterval(() => {
          getLastCordinates(item?.driverId);
        }, 10 * 1000);
        return () => clearInterval(intervalId);

      }
    }

    // calculateRoute()

  }, [vehicle, vehicleList, city]);


  useEffect(() => {

    calculateRoute();
  }, [vehicleMappedCluster]);

  useEffect(() => {
    // console.log("selected vehicle", selectVehicle);

    let vehicleClusterLatLng: any[] = [];

    let vehicleCluster: any[] = selectVehicle?.clustermapped?.filter((element: any) => {
      return element.isChecked;
    });

    if (vehicleCluster?.length) {
      for (let element of vehicleCluster) {
        for (let cluster of clusterList) {
          if (element?.clusterDDN === cluster?.clusterDDN) {
            vehicleClusterLatLng.push([
              cluster?.location?.coordinates[1],
              cluster?.location?.coordinates[0],
            ]);
          }
        }
      }
    }

    let count: number = 0;
    for (let i = 0; i < selectVehicle?.clustermapped?.length; i++) {
      if (selectVehicle?.clustermapped[i].isChecked) count++;
    }
    setTotalMappedCluster(count)

    setDirectionCordinate(vehicleClusterLatLng);
    setVehicleMappedCluster(vehicleClusterLatLng);

  }, [selectVehicle]);



  const getLastCordinates = async (p_wwid: string) => {
    const lastCordinates = await getLastCordinatesOfVehicle({ wwid: p_wwid });
    setCurrentVehicleCordinate([parseFloat(lastCordinates[0].lat), parseFloat(lastCordinates[0].lng)]);
    return lastCordinates
  }

  useEffect(() => {
    // console.log("live cordinates", currentVehicleCoordinate)
  }, [currentVehicleCoordinate]);

  useEffect(() => {
    // console.log("scannedClusterList", scannedClusterList)
    setScannedClusters(scannedClusterList)
  }, [scannedClusterList, vehicle])


  async function calculateRoute() {
    // if (originRef.current.value === "" || destiantionRef.current.value === "") {
    //   return;
    // }

    let waypoints = [];
    for (let i = 1; i < vehicleMappedCluster.length - 1; i += 2) {
      if (waypoints.length < 25) {

        waypoints.push({ location: `${vehicleMappedCluster[i][0]}, ${vehicleMappedCluster[i][1]}` })
      }
    };

    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: `${vehicleMappedCluster[0]}`,
      destination: `${vehicleMappedCluster[vehicleMappedCluster.length - 1]}`,
      travelMode: window.google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: false,
      waypoints: waypoints
    });

    setDirectionsResponse(results);
  }
  const markerStyle = {
    backgroundColor: "green", // Set your desired background color
    borderRadius: "50%", // Ensures the marker is circular
    padding: "10px", // Adjust padding as needed
  };


  // const [infoWindow, setInfoWindow] = useState(null);

  //   const handleMouseOver = (id) => {
  //     setInfoWindow(id);
  //   };

  //   const handleMouseOut = () => {
  //     setInfoWindow(null);
  //   };

  useEffect(() => {

    setPolyLineCordinates([
      { lat: vehicleMappedCluster?.[0]?.[0], lng: vehicleMappedCluster?.[0]?.[1] },
      { lat: parseFloat(currentVehicleCoordinate[0]), lng: parseFloat(currentVehicleCoordinate[1]) }
    ])

  }, [currentVehicleCoordinate, vehicleMappedCluster])


  const onMouseOver = ((item: string) => {
    setShowClusterNmae(item)
  });
  const handleOnMouseOut = (() => {
    setShowClusterNmae("")
  });

  // Distance service console.log("distanceMatrixCallback",response);
  const distanceMatrixCallback = (response: any) => {
    // console.log("distanceMatrixCallback before",response?.rows[0]?.elements[0] );
    if (response !== null && response?.rows[0]?.elements[0]?.status === "OK") {
      setDistanceTravelled(response?.rows[0]?.elements[0]?.distance?.text)

    }
  };

  const distanceMatrixOption: any = {
    destinations: [`${currentVehicleCoordinate}`],
    origins: [`${vehicleMappedCluster[0]}`],
    travelMode: "DRIVING"
  }
  return isLoaded ? (
    <div className="w-full mt-8 ml-2 h-1/3 ">
      <div className="flex justify-between mr-4  ">
        <div className="p-2 flex lex-row ">

          <DropDown list={vehicleListArr} selected={vehicle} setSelected={setvehicle} />
          <div className="grid grid-cols-3 gap-8 ml-8 ">
            <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Vehicle Number</div>
              <div className='text-xl font-500'> {`${selectVehicle?.vehicleNo || "NA"} `}</div>
            </div><div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Driver Name/Id</div>
              <div className='text-xl font-500'> {`${selectVehicle?.driverId || "NA"}`}</div>
            </div><div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Avg. Mileage</div>
              <div className='text-3xl font-500'> {`${selectVehicle?.mileage || "NA"}`}</div>
            </div>
            <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Distance Travelled Today</div>
              <div className='text-3xl font-500'> {`${distanceTravelled}`}</div>
            </div>


            <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Avg Fuel consumption</div>
              <div className='text-3xl font-500'> {`${7} L`}</div>
            </div>
            <div className="bg-sky-50  rounded rounded-lg p-2 text-start">
              <div className=" text-sm">Total Mapped Clusters</div>
              <div className='text-3xl font-500'> {`${totalMappedCluster}`}</div>
            </div>

          </div>
        </div>
        {/* <button type='submit' className='bg-recity hover:bg-blue-700 text-white font-bold py-2 ml-2 px-4 rounded' onClick={()=> calculateRoute()}>Path</button> */}
        <div className="flex justify-end mr-4">
          <CalenderSelctor calendar={calendar} setCalendar={setCalendar} />
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >

        {clusterList.map((item) => (

          <Marker onMouseOver={() => { onMouseOver(item?.clusterDDN) }} onMouseOut={() => handleOnMouseOut()} label={showCluserName === item?.clusterDDN ? item?.clusterDDN : ""} position={{ lat: item?.location?.coordinates[1], lng: item?.location?.coordinates[0] }} />

        ))}

        <Marker position={{ lat: parseFloat(currentVehicleCoordinate[0]), lng: parseFloat(currentVehicleCoordinate[1]) }}
          icon={{
            path: faTruckPickup.icon[4] as string,
            fillColor: "#0000ff",
            fillOpacity: 1,
            anchor: new google.maps.Point(
              faTruckPickup.icon[0] / 2, // width
              faTruckPickup.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
          }}
        />


        {scannedClusters?.map((item) => (
          <div className={``}>
            <Marker key={item?.clusterDDN} onMouseOver={() => { onMouseOver(item?.clusterDDN) }} onMouseOut={() => handleOnMouseOut()} label={showCluserName === item?.clusterDDN ? item?.clusterDDN : ""} position={{ lat: parseFloat(item?.lat), lng: parseFloat(item?.long) }}
              icon={{
                url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(
                  `<svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                <g transform="translate(0 -1028.4)">
                  <path d="m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z" fill="green"/>
                  <path d="m12.031 1.0312c-3.8657 0-6.9998 3.134-6.9998 7 0 1.383 0.4017 2.6648 1.0938 3.7498 0.0334 0.053 0.059 0.105 0.0938 0.157l4.3432 8.062c0.204 0.586 0.782 1.031 1.438 1.031s1.202-0.445 1.406-1.031l4.844-8.75c0.499-0.963 0.781-2.06 0.781-3.2188 0-3.866-3.134-7-7-7zm-0.031 3.9688c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5z" fill="green" transform="translate(0 1028.4)"/>
                </g>
              </svg>
              `
                ),
                scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
              }}
            />
          </div>

        ))}

        {city === "Mathura" &&

          <Marker label={"Mathura MRF"} position={{ lat: 27.56490, lng: 77.65611 }}
            icon={{
              url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(
                `<svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                  <g transform="translate(0 -1028.4)">
                    <path d="m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z" fill="yellow"/>
                    <path d="m12.031 1.0312c-3.8657 0-6.9998 3.134-6.9998 7 0 1.383 0.4017 2.6648 1.0938 3.7498 0.0334 0.053 0.059 0.105 0.0938 0.157l4.3432 8.062c0.204 0.586 0.782 1.031 1.438 1.031s1.202-0.445 1.406-1.031l4.844-8.75c0.499-0.963 0.781-2.06 0.781-3.2188 0-3.866-3.134-7-7-7zm-0.031 3.9688c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5z" fill="yellow" transform="translate(0 1028.4)"/>
                  </g>
                </svg>
                `
              ),
              scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
            }}
          />
        }



        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
        {/* {user2directionsResponse && (
          <DirectionsRenderer directions={user2directionsResponse} />
        )} */}
        <Polyline
          path={polylineCordinates}
          options={{
            strokeColor: "#FF0000",
            strokeWeight: 6,
            strokeOpacity: 0.6,
            visible: true
          }}
        />
        <DistanceMatrixService
          options={distanceMatrixOption}
          callback={distanceMatrixCallback}
        />


      </GoogleMap>
      {/* <VehicalSummary city={city} skip={0} limit={50} /> */}
    </div>
  ) : <></>
}

export default memo(GoogleMapComponent)