import React,{useState, useEffect, useCallback} from "react";

import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
// import FilterComponent from "./FilterComponent";
import {CSVLink} from 'react-csv'
import DateRangeSelector from '../../Ui-Elemnent/DateRange'
import format from 'date-fns/format';

import useVehicleDetails from "../dataFetch/vehicleDataFetch";
import { useNavigate } from "react-router-dom";
import AddVehicle from "./AddVehicle";
// import { IoSwapVerticalOutline } from "react-icons/io5";
const rowHeading = [  {heading:"Vehicle No.",}, {heading:"Driver ID", key:"driverId"}, {heading:"Fuel Capacity",key: 'fuelCapacity'}, {heading:"Mileage",key: 'mileage'}, {heading:"City"},{heading:"Action",key: ''} ]


export default function VehicleTable({city,  skip, limit, setTotalCount, setCurrentPage}:{city:string, skip:number, limit:number, setTotalCount:any, setCurrentPage:any}) {
    const navigate = useNavigate();
     ////// Date Range Selector
     const [dayDiff, setDaydiff] =useState<any>()
     const today = new Date()
     const [toDate, setToDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [fromDate, setFormDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
     const [range, setRange] = useState<any[]>([
       {
         endDate: new Date(),
         startDate: today.setDate(today.getDate()-6),
         key: 'selection', 
       }
     ]);
 
     useEffect(()=>{
 
         setToDate(format(range[0].endDate, "yyyy-MM-dd"))
         setFormDate(format(range[0].startDate, "yyyy-MM-dd"))
     
         setDaydiff((range[0].endDate - range[0].startDate) / 86400000);
     
       },[range, toDate, fromDate]);
     ///////////////

     useEffect(()=>{
        setCurrentPage(1)
     },[range, toDate, fromDate])

    const { vehicleList, loading} = useVehicleDetails({city:city,skip:skip, limit:limit});
    const [openAddVehicle, setAddVehicle] = useState<boolean>(false)
    const [allPropertyList, setAllPropertyList] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [property, setProperty] = useState<any>({});
    const [filteredColumn, setFilteredColumn] = useState<any[]>([]);
    
    
    type Data = typeof vehicleList;
    type SortKey =  keyof Data[0];
    type SortOrder = 'ascn' | 'desc'
    useEffect(()=>{
        setData(vehicleList)
        
    },[vehicleList])

    const headers = [
        { label: 'PU DDN', key: 'PUDDN' },
        { label: 'Owner Name', key: 'ownersname' },
        { label: 'Contact No.', key: 'contactNo' },
        { label: 'Ward No.', key: 'wardNo' },
        { label: 'No of Days waste not segregated', key: 'noOfDaysWaste' },
      ];
      useEffect(()=>{
        setFilteredColumn(rowHeading);
      
      },[rowHeading]);

   // sorting functionality
    const [sortKey, setSortKey] = useState<SortKey>('');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
   
    
      function sortData({tableData, sortKey, reverse}:{tableData:Data, sortKey:SortKey, reverse:boolean}){
    //    console.log("sortKey", sortKey);
       
        if(!sortKey) return tableData
      
       const sortedData =  tableData.sort((a, b) => 
       {if(sortKey === 'wardNo'){
        return Number(a[sortKey] ) > Number(b[sortKey]) ? 1 :-1
       }
        return a[sortKey] > b[sortKey] ? 1 :-1}
       )

       if(reverse){ return sortedData.reverse()}
        return sortedData
      }

      const sortedData = useCallback(()=>sortData({tableData: vehicleList, sortKey, reverse : sortOrder === 'desc'}),[vehicleList, sortKey, sortOrder])

      ///
      useEffect(()=>{
        setData(sortedData())
    },[sortedData]);

      useEffect(()=>{
        setAllPropertyList(data.slice(skip, (skip + limit)))
    },[skip, limit, data, sortedData]);
    
    

    const handleChange = ( value:string)=>{
        setSearchText(value)
           
         }   

        useEffect(()=>{
            filterData(searchText)
        },[searchText])

    const filterData = (value:string)=>{
        const lowerCaseValue =  value.toLowerCase().trim();
        // console.log("lowerCaseValue",lowerCaseValue);
        
        if(!lowerCaseValue){
            setData(sortedData())
        }else {
            const filteredData = sortedData().filter(item =>{
                // console.log("item", item)
                return Object.keys(item).some(key =>{
                   
                    return item[key].toString().toLowerCase().includes(lowerCaseValue)
                })
            });
            // console.log("filteredData",filteredData);
            
            setData(filteredData)
            // console.log(data);
            
        }
    } 
        const handleSortClick = (sortKey:string)=>{
            setSortOrder(sortOrder === 'ascn'? 'desc':'ascn')
            setSortKey(sortKey)
            // console.log(sortKey);
            
        }   
        
        const handleViewVehicle = async (vehicle:any)=>{
            navigate(`/fleet/vehicle/${vehicle._id}`)
            // setProperty(property)
        //    const propertyDetails =await getPropertyDetails(ddn)
        // setOpenpropertyDetails(true);
            // console.log(property)
        }
  
return (
        <div className="flex flex-col">
            <div className="overflow-x-auto ">
                <div className="flex justify-start py-3 pl-2 ">

                    {/* <div className="relative max-w-xs mr-4">
                        <label htmlFor="hs-table-search" className="sr-only">
                            Search
                        </label>
                        <input
                            type="text"
                            name="hs-table-search"
                            id="hs-table-search"
                            className="block w-full p-4  pl-10 text-sm border rounded-md focus:border-recity focus:ring-darkBlue"
                            placeholder="Search..."
                            value={searchText}
                            onChange={e => handleChange(e.target.value)}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg
                                className="h-3.5 w-3.5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </div>
                    </div> */}

                    <div className="flex items-center border border-gray rounded-md mx-4" >
                        <div className="relative">
                            <button className="relative z-0  inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" onClick={()=> navigate("/fleet/vehicle/add")} >
                                <span className="relative inline-flex items-center px-3 py-3  space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                   
                                    <div className="hidden sm:block">
                                        Add Vehicle
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* <div className="flex items-center border border-gray rounded-md mr-4" >
                        <div className="relative">
                        <CSVLink data={sortedData()} filename={`Defaulters_list_${city}_${fromDate}_${toDate}`} headers={headers}>
                            <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1" >
                                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white  rounded-md sm:py-2">
                                    <div>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </div>
                                    <div className="hidden sm:block">
                                        Download 
                                        
                                    </div>
                                </span>
                            </button>
                            </CSVLink>
                        </div>
                    </div> */}
                    {/* <div className='flex justify-end  '>
                    { <DateRangeSelector range={range} setRange={setRange}  />}

                </div> */}
                </div>
                {/* <div className="absolute justify-center z-20 ">
                    {openFilter && <FilterComponent openFilter={openFilter} setOpenFilter={setOpenFilter}  filteredColumn={filteredColumn} setFilteredColumn={setFilteredColumn}/>}
                </div> */}
                
                <div className="p-1.5 inline-block min-w-full align-middle">
                    <div className="overflow-visible border rounded-lg">
                    {loading ? <ScaleLoaderItem loading={loading}/> :
                    
                        <table className="min-w-full divide-y divide-recity">
                            <thead className="bg-recity">
                                <tr>
                                    {
                                        filteredColumn.map((item, index) =>{
                                            return(
                                                <th key={index}
                                        scope="col"
                                        className="px-6 py-3 text-l font-bold text-center text-white uppercase "
                                        onClick={e=>handleSortClick(item.key)}
                                    >
                                        {item.heading }
                                        
                                        {/* {item.isChecked && item.heading  ===  "No. of days waste not segregated"  ? ' ⇵' : '' }{item.isChecked && item.heading ===   'Ward no.' ? ' ⇵' : ''}{item.isChecked && item.heading ===  "Owner's Name"  ? ' ⇵' : '' } */}
                                    </th>
                                            )
                                        })
                                    }
                                    
                                    
                                    
                                </tr>
                            </thead>
                           
                            <tbody className="divide-y divide-gray-200">
                                {
                                    vehicleList?.map((item, index)=>{
                                        // console.log("item", item);
                                        
                                        return(
                                            <tr key={index}>

                                            {/* <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap uppercase">
                                                {filteredColumn[0].isChecked && item?.propertyOwnerName}
                                            </td> */}
                                            <td className="px-2 uppercase py-4 text-sm text-gray-800 whitespace-nowrap">
                                                { item?.vehicleNo}
                                            </td>
                                            <td className=" py-4 text-sm text-gray-800 whitespace-nowrap">
                                            { item?.driverId}
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {` ${item?.fuelCapacity}`  }
                                                
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {` ${item?.mileage}`  }
                                                
                                            </td>
                                            {/* <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                                    {` ${item?.clusterMapped}`  }
                                                
                                            </td> */}
                                            <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                               
                                               { item?.city}
                                           
                                       </td>
                                       {/* <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                               
                                               { (item.state) }
                                           
                                       </td> */}
                                            {/* <td className={`px-6 py-4 text-sm font-medium ${filteredColumn[8].isChecked ? "": 'hidden'} whitespace-nowrap`}>
                                                
                                                    { item.userCreatedBy}
                                            </td> */}
                                            <td className={`px-6 py-4 text-sm font-medium  whitespace-nowrap`}>
                                            <button
          type="button"
          className={`border rounded bg-lightBlue hover:bg-recity hover:text-white p-2 disabled:opacity-25`}
          onClick={()=>handleViewVehicle(item)}
        //   disabled={currentPage * limit > totalCount ? true : false}
        >
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        </button>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                          
                            </tbody>
                        </table>
                }
                    </div>
                </div>

            </div>
            
            {/* {openAddVehicle && <AddVehicle city={city} />} */}
        </div>
    );
}