import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useClusterListData = ({city,  skip, limit, setTotalCount}:{city:string, skip:number, limit:number, setTotalCount:any}) => {
    let token = localStorage.getItem("auth-token") || "";
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({});
  // const [propertyType,setPropertyType] = useState<string[]>([]);
    

  useEffect(() => {
    let didCancel = false;
    setError(null);
    (async () => {
      // console.log("Inside data fetch",filteredPropertyTypeArray)
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/clusters/all-clusters?city=${city}&skip=${skip}&limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-user-token": `${JSON.parse(token)}`
            },
          }
        );

        const result = await response.json();
        // console.log("result", result.data.clusterList);
        let ClustersData = result?.data?.clusterList;
        let totalClusterCount = result?.data?.totalCluster;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
        setTotalCount(totalClusterCount);
        setClusterList(ClustersData);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, skip, limit ]);




  return {  clusterList, loading, error };
};

export default useClusterListData ;
