import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useGetCordinateList = ({city, date}:{city:string, date:string}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [cordinateData, setCordinateData] = useState<any[]>([])
  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        // setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/workers-cordinate?date=${date}&city=${city}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result?.data);
        const cordinatesList = await result?.data?.cordinateData;
        setCordinateData(cordinatesList);
       
        return cordinateData;

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, date]);

  //  const cordinateList =  async ({date, city}:{date:string, city:string}) => {
  //     try {
      
  //       const response = await fetch(
  //         `${BASEURL}/v1/insights/workers-cordinate?date=${date}&city=${city}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       const result = await response.json();
  //       // console.log("result", result?.data);
  //       const cordinatesList = await result?.data?.cordinateData

  //       setCordinateData(cordinatesList);
       
  //       return cordinateData;
  //     } catch (err) {
       
  //     } 
  //   };

  return { cordinateData };
};

export default useGetCordinateList ;
