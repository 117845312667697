import { useState, useEffect, useRef } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format'
/*
const CalenderSelctor = ({calendar, setCalendar}:{calendar:Date,setCalendar:any}) => {
  const today = new Date();
  const refOne = useRef<any>(null);
  const [date, setDate] = useState<any>(today.setDate(today.getDate()-1));
  const [open, setOpen] = useState<boolean>(false);
  useEffect(()=>{
    document.addEventListener("keydown", hideOnEscKey, true);
    document.addEventListener("click", hideOnClickOutside, true)
  },[])
  const hideOnEscKey =(e:any)=>{
    if(e.key === "Escape"){
      setOpen(false)
    }
  }

  const hideOnClickOutside =(e:any)=>{
    if(refOne.current && !refOne.current.contains(e.target)){
      setOpen(false)
    }
    
  }
  const handleSelect = (date:any)=>{
    setDate(date)
  }
  const handleApply =()=>{
    setCalendar(date);
    setOpen(false)
  }
  const handleReset =()=>{
    setDate(new Date())
  }
  
  return (
    
    <div  className={` mr-2 z-20 bg-white  ${open ? "absolute":""}`}>
      <div className='border rounded px-3 py-3 space-x-2border-indigo-800 min-w-min  cursor-pointer relative' onClick={()=>setOpen(!open)}>
        {`${format(calendar, "yyyy-MM-dd")}`}
        
      
      </div>
      <div ref={refOne} className="">

      {open &&
      <>
         <Calendar  onChange={handleSelect} 
                     date={date}
                     maxDate={new Date()}
         />

         <div className='flex justify-between w-1/3 px-4 mb-4'>
         <button className='bg-recity text-white px-2  border rounded-lg mx-2 hover:bg-darkBlue' onClick={handleApply}>Apply</button>
         <button className='bg-recity text-white px-2 border rounded-lg hover:bg-darkBlue'onClick={handleReset}>Reset</button>
       </div>
       </>
      }
      </div>
    </div>
  )
}

export default CalenderSelctor
*/
export default function CalenderSelctor({calendar, setCalendar}:{calendar:Date,setCalendar:any}) {
  const today = new Date();
  const refOne = useRef<any>(null);
  const [date, setDate] = useState<any>(today.setDate(today.getDate()-1));
  const [open, setOpen] = useState<boolean>(false);
  useEffect(()=>{
    document.addEventListener("keydown", hideOnEscKey, true);
    document.addEventListener("click", hideOnClickOutside, true)
  },[])
  const hideOnEscKey =(e:any)=>{
    if(e.key === "Escape"){
      setOpen(false)
    }
  }

  const hideOnClickOutside =(e:any)=>{
    if(refOne.current && !refOne.current.contains(e.target)){
      setOpen(false)
    }
    
  }
  const handleSelect = (date:any)=>{
    setDate(date)
  }
  const handleApply =()=>{
    setCalendar(date);
    setOpen(false)
  }
  const handleReset =()=>{
    setDate(new Date())
  }
return (
  <div >
     <div className='border rounded-md border-indigo-800 min-w-min  px-3 py-3 space-x-2  cursor-pointer relative' onClick={()=>setOpen(!open)}>
     {`${format(calendar, "yyyy-MM-dd")}`}
       
    
   </div>
    {open ? (
      <>
        <div 
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto ">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              
              <div className='border rounded-md border-indigo-800 min-w-min  px-3 py-3 space-x-2  cursor-pointer relative' onClick={()=>setOpen(!open)}>
              {`${format(calendar, "yyyy-MM-dd")}`}
             
          </div>
             
              {/*body*/}
              <div className="relative p-6 pt-2 flex-auto">
              <Calendar  onChange={handleSelect} 
                     date={date}
                     maxDate={new Date()}
         />
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                    className="bg-lightBlue text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={ handleReset}
                  >
                    Reset
                  </button>
                  <button
                    className="bg-recity text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={ handleApply}
                  >
                    Apply
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    ) : null}
  </div>
);
}