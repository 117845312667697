import React from 'react'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement,} from 'chart.js';
 
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,BarElement,)
const BarGraph = ({chartData, option} : {chartData:any, option:any}) => {
  return (
    <div className='h-96 w-full'>
        <Bar data={chartData}  options={{ ...option}}/>
    </div>
  )
}

export default BarGraph