import {  useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useClusterInsightsData = ({city, date}:{city:string, date:string}) => {

  const [clustersInsightsData, setClusterInsightsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  
  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/reports/cluster-insight?city=${city}&date=${date}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        // console.log("result", result);
       
          
        // console.log("propertiesInfo", propertiesInfo);
        
        setClusterInsightsData(result?.data);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, date]);

  return {  clustersInsightsData, loading, error };
};

export default useClusterInsightsData ;
