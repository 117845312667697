import React,{useState, useEffect} from 'react'

import Table from './Table'
import { DropDown } from '../Ui-Elemnent/DropDown'

const UserFeeCollectionList = ({city}:{city:string}) => {
    
   
      // Pagiation
      const [totalCount, setTotalCount] = useState<number>(0)
      const [skip, setSkip] = useState<number>(0);
      const [limit, setLimit] = useState<number>(10);
      const [currentPage, setCurrentPage] = useState<number>(1)

      useEffect(()=>{
        setSkip((currentPage -1) * limit)
        
      },[currentPage, limit ])

      const clickPrevious = ()=>{
      currentPage !== 1 ?  setCurrentPage(currentPage - 1) :setCurrentPage(1)
      }
      const clickNext = ()=>{
         setCurrentPage(currentPage + 1) 
        }
        useEffect(()=>{
          setCurrentPage(1);
        },[city]);

  return (
    <div className=''>
            <div className='w-full ml-2 mt-8 h-1/2'>
     
      <div className=''>
      
        <div>
            <Table city={city}  skip={skip} limit={limit} setTotalCount={setTotalCount} setCurrentPage={setCurrentPage}/>
        </div>
    <div>
     
    </div>
    </div>
    </div>

    <div className='flex justify-start w-1/2 items-end mt-2 mx-8'>
        <button className={`border rounded bg-lightBlue hover:bg-recity hover:text-white p-2 disabled:opacity-25 mr-2 `} onClick={clickPrevious} disabled={currentPage === 1 ? true : false} >Previous</button>
        <button type='button' className={`border rounded bg-lightBlue hover:bg-recity hover:text-white p-2 disabled:opacity-25`} onClick={clickNext} disabled={(currentPage * limit) >  totalCount ? true: false}>Next</button>
        <div className='mx-5'>{`Showing ${skip + 1 > totalCount ? totalCount :  skip + 1 } to ${ currentPage * limit >  totalCount ? totalCount :currentPage * limit} out of ${totalCount} Properties`}</div>
        <DropDown list={[10,20,50]} selected={`${limit} entries per row`} setSelected={setLimit}/>
    </div>
    </div>
  )
}

export default UserFeeCollectionList