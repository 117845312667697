import { useCallback, useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useUpdateCluster = () => {
  let token = localStorage.getItem("auth-token") || "";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

    let didCancel = false;
  

    const updateCluster = async (id:string, body:string) => {
      try {
        // setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/clusters/${id}`,
          {
            method:"PUT",
            body:body,
            headers: {
              "Content-Type": "application/json",
              // "x-user-token": token
              "x-user-token": `${JSON.parse(token)}`
            },
          }
        );

        const result = await response.json();
        console.log("result", result?.data?.clusterDetails);
        
        // let propertyData = result?.data?.tSPList?.propertiList;
        // let totalproperties = result?.data?.tSPList?.totalCount;
        // const propertyDDN = Object.keys(result.data.defaulterList);
        // const propertiesInfo = propertyDDN.map((ddn) => {
        //     return  {noOfDaysWaste  : result.data.defaulterList[ddn].noOfDefaulterDays, PUDDN:result.data.defaulterList[ddn].PUDDN, ownersname:result.data.defaulterList[ddn].ownersname, contactNo:result.data.defaulterList[ddn].contactNo,wardNo:result.data.defaulterList[ddn].wardNo  };
        //   });
          
        // console.log("propertyDDN", propertyDDN);
      
        // setClusterDetails(result?.data?.clusterDetails);
        
        if (!didCancel) {
      
          didCancel =true
          }
          return result;
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    const updateImage = async (file: any) => {
        try {
        //   setLoading(true);
        const formData = new FormData();
        formData.append('clusterImg', file);
        //  console.log("body", body)
          const response = await fetch(
            `${BASEURL}/v1/clusters/upload-img`,
            {
              method:"POST",
              body:formData,
              headers: {
                // "x-user-token": token
                mode: "cors",
                "x-user-token": `${JSON.parse(token)}`
              },
            }
          );
  
          const result = await response.json();
          console.log("result", result);
          
         
          if (!didCancel) {
        
            didCancel =true
            }
            return result;
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }


  return { updateCluster, updateImage, loading, error };
};

export default useUpdateCluster ;
