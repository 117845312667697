import BarGraph from '../../../Graphs/BarGraph'
import ScaleLoaderItem from '../../../../Ui-Elemnent/ScaleLoader'
import useAttendanceDetails from './data-fetch/fetchAttendanceData'
const AttendanceChart = ({city, fromDate, toDate, setTotalUser, setPresentUser }:{city:string, fromDate:string, toDate:string, setTotalUser:any, setPresentUser:any}) => {
  const {attendanceData, loading} = useAttendanceDetails({city:city, fromDate:fromDate, toDate:toDate, setTotalUser:setTotalUser, setPresentUser:setPresentUser})
  const option = {
    maintainAspectRatio: false,
    
  }
  return (
    <div>
      {loading ? <ScaleLoaderItem loading={loading } /> : <BarGraph  chartData={attendanceData} option={option} /> }
    </div>
    
  )
}

export default AttendanceChart