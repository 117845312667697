
import { useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useAttendanceDetails = ({city, calendar, setPresentUserCount, setTotalUseCount}:{city:string, calendar:string, setPresentUserCount:any, setTotalUseCount:any}) => {

  
  const [attendanceData, setAttendanceData] = useState<{labels: any[]; datasets: any[]}>({
      labels:[],
      datasets: [ {
        label: "Total User",
        data:  [0],//collectionData?.map((obj:any) => obj.collectionCount),
        borderColor: "#ADD8E6",
        backgroundColor: "#23418D",
      },
      {
        label: "Present User",
        data:  0,//collectionData?.map((obj:any) => obj.collectionCount),
        borderColor: "#ADD8E6",
        backgroundColor: "#ADD8E6",
      }],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/insights/attendance/ward?city=${city}&to=${calendar}&from=${calendar}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        const wards = Object.keys(result.data.attendanceDataByWard);
        if(!wards.length ){
         setPresentUserCount(0);
          setTotalUseCount(0);
          setAttendanceData({
            labels: wards,
                    datasets: [
                      {
                        label: "Total User",
                        data: [0],//collectionData?.map((obj:any) => obj.collectionCount),
                        borderColor: "#ADD8E6",
                        backgroundColor: "#23418D",
                        maxBarThickness: 50
                      },
                      {
                        label: "Present User",
                        data: [0],//collectionData?.map((obj:any) => obj.collectionCount),
                        borderColor: "#ADD8E6",
                        backgroundColor: "#ADD8E6",
                        maxBarThickness: 50
                      },
                    ],
          });
          didCancel =true
          }else{
            const presentUser = wards.map((date) => {
                      return result.data.attendanceDataByWard[date].present_user_count;
                    });
                    const totalUser = wards.map((date) => {
                      return result.data.attendanceDataByWard[date].total_user_count;
                    });
            if (!didCancel) {
              setPresentUserCount(presentUser.reduce((a,b)=>a+b));
              setTotalUseCount(totalUser.reduce((a,b)=>a+b))
              setAttendanceData({
                labels: wards,
                        datasets: [
                          {
                            label: "Total User",
                            data: totalUser || [0],//collectionData?.map((obj:any) => obj.collectionCount),
                            borderColor: "#ADD8E6",
                            backgroundColor: "#23418D",
                            maxBarThickness: 50
                          },
                          {
                            label: "Present User",
                            data: presentUser || [0],//collectionData?.map((obj:any) => obj.collectionCount),
                            borderColor: "#ADD8E6",
                            backgroundColor: "#ADD8E6",
                            maxBarThickness: 50
                          },
                        ],
              });
              didCancel =true
              }
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [city, calendar]);

  return {   attendanceData, loading, error };
};

export default useAttendanceDetails ;
